<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'vue-file-agent/dist/vue-file-agent.css';

import { SlideYUpTransition } from 'vue2-transitions';

import financingService from '@/services/Financing/financingService';
import periodicInformationService from '@/services/PeriodicInformation/periodicInformationService';
import bancarizadoresService from '@/services/Bancarizadores/bancarizadoresService';
import fiduciaService from '@/services/Fiducia/fiduciaService';
import ordersService from '@/services/Orders/ordersService';
import socialTransformationService from '@/services/SocialTransformation/socialTransformationService';

import Vue from 'vue';
import VueFileAgent from 'vue-file-agent';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import attachmentsService from '@/services/Attachments/attachmentsService';
import pessoasService from '@/services/Pessoas/pessoasService';
import settingsService from '@/services/Settings/settingsService';
import { collectionRef } from '@/firebase';

import { vueTopprogress } from 'vue-top-progress';
import { quillEditor } from 'vue-quill-editor';

import Modal from '@/components/Modal';
import ReportLayout from '@/components/Financing/ReportLayout';
import { DoubleBounce } from 'vue-loading-spinner';

import store from '@/store/index';

import moment from 'moment';
import { Printd } from 'printd';
import logsService from '@/services/Logs/logsService';
import { withLog } from '@/logger/with_log';

import FileUpload from 'vue-upload-component';
import ImageCompressor from '@xkeshi/image-compressor';

import {
  FinanciamentoPadrao,
  CWLStatusCampanha,
  PessoaCaracteristica,
  EnderecoPadrao,
  BancarizadorIntegracaoID
} from '@/store/cwl_enums';
import axios from 'axios';
import { VTooltip } from 'v-tooltip';

import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

Vue.use(VueFileAgent);
Vue.component('v-select', vSelect);
Vue.directive('tooltip', VTooltip);

import { cnpj } from '@/validate/customValidators.js';

import * as municipios from '@/assets/data/municipios.json';
import { or } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      showConfirmarInvestimentoModal: false,
      showConfirmarInvestimentoModalHeader: true,
      orders: [],
      
      updating: false,
      isAgenteSocial: false,
      modalidade: process.env.VUE_APP_TYPE,
      submodalidade: process.env.VUE_APP_SUBTYPE,
      content: '',
      contrato_personalizado_content: '',
      editorOption: {
        placeholder:
          'Descreva um pouco mais. Lembre-se: aqui é onde você conta sua história para as pessoas apoiarem a sua causa.',
      },

      editorContratoPersonalizadoOptions: {
        placeholder:
          'Digite aqui o contrato personalizado da sua oferta.',
      },

      editorCuradoriaOption: {
        placeholder: 'Escreva aqui suas observações sobre a curadoria.',
      },

      CWLStatusCampanha: CWLStatusCampanha,
      PessoaCaracteristica: PessoaCaracteristica,
      financing: {
        hasMinimalCapture: false,
        curadoria: {},
        dados_complementares: {},
      },
      financingAccounts: {
        beneficiary: {},
        platform: {},
        loaded: false,
      },
      cachedFinancing: {
        hasMinimalCapture: false,
      },

      allOrders: [],
      hasAnyPendingOrder: false, // Atributo para indicar se há ordens pendentes
      hasAnyPaidOrder: false, // Atributo para indicar se há ordens pagas
      dynamicFields: [],
      relevantInformations: '',
      allSettings: {},
      plataformTotalInvested: 0,
      categorias: [],
      bancarizadores: [],
      estados: [],
      cidades: [],
      bancos: [],
      modalidades: [],
      avalistas: [],
      showDebtFields: false,
      /**
       * Terms with modal
       */
      showModalTermos: false,
      acceptTerm: false,
      /**
       * Taxas e Prazos with modal
       */
      showModalTaxasPrazos: false,

      /**
       * Publicar automaticamente
       */
      publicarAutomaticamente: false,

      /**
       * Campanha para PJ
       */
      isForPJ: false,
      pessoaPJ: null,
      pessoaResponsavelPelaEmpresa: {},
      cnpjForSearch: '',
      isLoadingPessoaPJ: false,
      showPJFields: false,
      blockByPJError: false,
      skipCNPJSearch: false,

      cepForSearch: '',
      isLoadingEnderecoPessoaPJ: false,
      showPJAddressFields: false,
      skipCepSearch: false,

      isLoadingPJCidades: false,

      fileRecords: [],
      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },
      isLoadingCidades: false,

      /**
       * Curadoria
       */
      criador_da_campanha_nome: '',
      criador_da_campanha_email: '',
      criador_da_campanha_cpf: '',
      criador_da_campanha_telefone: '',
      criador_da_campanha_nascimento: '',

      dados_bancarios: {
        codigo_banco: '',
        agencia_numero: '',
        agencia_digito: '',
        conta_numero: '',
        conta_digito: '',
      },

      textoDadosBancarios:
        'Para podermos realizar a remessa dos valores, você deve ser o titular da conta abaixo',
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Pular tutorial',
          buttonPrevious: 'Voltar',
          buttonNext: 'Próximo',
          buttonStop: 'Finalizar',
        },
      },
      steps: [
        {
          target: '.ql-bold', // We're using document.querySelector() under the hood
          header: {
            title: 'Tornar o texto negrito',
          },
          content: `Este botão torna um texto comum em <strong>negrito</strong>!`,
        },
        {
          target: '.ql-link',
          header: {
            title: 'Adicionar link',
          },
          content: 'Este botão permite que você adicione links.',
        },
        {
          target: '.ql-image',
          header: {
            title: 'Adicionar Imagens',
          },
          content: 'Este botão permite que você adicione imagens.',
        },
      ],

      operacaoErrorShown: false,
      operacaoErrorMessage: "",
      operacaoEmAndamento: false,
      operacaoConcluida: false,
      bancarizadorEncerramentoOperacao: undefined,

      showExcluirOfertaModal: false,

      BancarizadorIntegracaoID: BancarizadorIntegracaoID,

      isLoadingButtonDisabled: false,

      //periodic infos
      infoTitle: '',
      created_at: '',
      infoDescription: '',
      infoFile: [],
      relevantInfos: [],

      file: {
        selectedFilename: '',
        fileName: 'pdf-default',
        randonNameFile: ''
      },
      //end periodic infos
      isProductSubtypeP1: process.env.VUE_APP_SUBTYPE == '588',
    };
  },
  validations: {
    cnpjForSearch: {
      valid: cnpj,
    },
  },
  components: {
    vueTopprogress,
    quillEditor,
    Modal,
    DoubleBounce,
    ReportLayout,
    SlideYUpTransition,
    FileUpload,
  },
  computed: {
    objId() {
      return this.$route.params.id;
    },
    editor() {
      return this.$refs.quillEditor.quill;
    },
    editorContratoPersonalizado() {
      return this.$refs.quillEditorContratoPersonalizado.quill;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    role() {
      const value = this.$store.getters.userClaim
        ? this.$store.getters.userClaim.role
        : '';
      return value.toLowerCase();
    },
    isAdminOrGestorOrCurador() {
      return (
        this.role == PessoaCaracteristica.Master ||
        this.role == PessoaCaracteristica.Gestor ||
        this.role == PessoaCaracteristica.Curador ||
        this.role == 'master'
      );
    },
    isAdminOrGestor() {
      return this.role == 'master' || this.role == PessoaCaracteristica.Gestor;
    },
    isGestor() {
      return this.role == PessoaCaracteristica.Gestor;
    },
    isAdmin() {
      return this.role == 'master';
    },
    isCurador() {
      return this.role == PessoaCaracteristica.Curador;
    },
    hasCnpj() {
      const status = this.financing.status;
      return (
        this.role == PessoaCaracteristica.Curador ||
        (status != CWLStatusCampanha.EmCriacao &&
          status != CWLStatusCampanha.Reprovada)
      );
    },
  },
  async mounted() {
    this.isForPJ = this.modalidade == 'equity';
    this.financing = this.$CWLNewFinanciamentoPadrao();
    this.cachedFinancing = this.$CWLNewFinanciamentoPadrao();

    if ( this.modalidade == 'equity' ) {
      if ( this.submodalidade == '588' ) {
        this.modalidades = [
          {
            name: this.$t('financing.modalityEquity'),
            code: 'equity'
          },
          {
            name: this.$t('financing.modalityDebt'),
            code: 'debt'
          }
        ];
      }
      else if ( this.submodalidade == 'bacen' ) {
        this.modalidades = [
          {
            name: this.$t('financing.modalityBacen'),
            code: 'bacen'
          }
        ];
      }
    }

    await this.get();
    this.handleBankFormTextDescription(this.financing.is_empresa);
    this.getSettings();
    this.getDynamicFields();
    this.getAllFinancingOrders();
    this.getCategorias();
    this.getBancarizadores();
    this.getBancos();
    this.getAvalistas();
    this.initTour();

    socialTransformationService
      .isSocialAgentByUserId(this.currentPessoa.pessoa_id)
      .then((value) => (this.isAgenteSocial = value));
  },
  methods: {
    onInputDateChange(reference, event) {

      const newEncerramento = moment(this.financing.fields['Encerramento da Oferta'].value);
      const prazoOfertaInMonths = this.financing.fields['prazo_oferta'].value || 0;

      let newDate = this.calculateNewDate(newEncerramento, prazoOfertaInMonths);

      if (reference == 'Encerramento da Oferta') {

        if (this.hasAnyPaidOrder) {
          this.$notify({
            title: 'Ooopss',
            message:
              'A oferta já possui aporte(s) pago(s)! Não é possível alterar a data de encerramento.',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });

          const prevValue = this.financing.dates.end_at && moment(this.financing.dates.end_at).format('YYYY-MM-DD');
          this.financing.fields['Encerramento da Oferta'].value = prevValue;

          return;
        }

        if (this.hasAnyPendingOrder) {
          this.$notify({
            title: 'Ooopss',
            message:
              'A oferta possui aporte(s) pendente(s)! Não é possível alterar a data de encerramento. Confirme que nenhum investidor fará o pagamento do(s) aporte(s) em andamento, e execute o encerramento da operação para alterar a data.',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 5000,
          });

          const prevValue = this.financing.dates.end_at && moment(this.financing.dates.end_at).format('YYYY-MM-DD');
          this.financing.fields['Encerramento da Oferta'].value = prevValue;

          return;
        }

        this.$set(this.financing.fields['primeiro_vencimento'], 'value', newDate)
      }

      if (reference == 'primeiro_vencimento' && event.target.value) {
        if (moment(this.financing.fields['primeiro_vencimento'].value).isBefore(newDate)) {
          this.$set(this.financing.fields['primeiro_vencimento'], 'value', newDate);
        }
      }

    },
    onInputNumberChange(reference, event) {

      const encerramentoOferta = this.financing.fields['Encerramento da Oferta'].value;
      const prazoOferta = event.target.value;

      if (reference == 'prazo_oferta' && encerramentoOferta && prazoOferta && !this.hasAnyPendingOrder && !this.hasAnyPaidOrder) {
        let newDate = this.calculateNewDate(encerramentoOferta, prazoOferta);
        this.$set(this.financing.fields['primeiro_vencimento'], 'value', newDate);
      }
    },
    calculateNewDate(startDate, monthsToAdd) {
        let newDate = moment(startDate).add(monthsToAdd, 'months');

        // Adiciona o número de dias para mover a data para segunda-feira, se necessário
        const daysToAdd = { 6: 2, 7: 1 }[newDate.isoWeekday()] || 0;
        newDate.add(daysToAdd, 'days');

        // Retorna a data formatada
        return newDate.format('YYYY-MM-DD');
    },
    statusDescription(status) {
      return this.$CWLFinancingStatus(status);
    },
    modalidadeDescription(modalidade) {
      return modalidade ? modalidade.name : "Não informado";
    },
    onEditorBlur(quill) {},
    onEditorFocus(quill) {},
    onEditorReady(quill) {},
    onEditorChange({ quill, html, text }) {
      this.content = html;
      this.financing.content = html;
    },
    onContratoPersonalizadoChange({ html }) {
      this.contrato_personalizado_content = html;
      this.financing.contrato_personalizado = html;
    },
    onCuradoriaEditorChange({ quill, html, text }) {
      this.financing.curadoria.observacao = html;
    },
    onRelevantEditorChange({ html }) {
      this.relevantInformations = html;
      this.financing.relevantInformations = html;
    },
    calcDaysBetweenDates(initialDate, finalDate) {
      const diffTime = Math.abs(finalDate - initialDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;
    },
    handleChangeAvalistaPrincipal() {
      if (this.financing.demaisAvalistas && this.financing.demaisAvalistas.length > 0) {
        this.financing.demaisAvalistas = this.financing.demaisAvalistas.filter(
          (avalista) => avalista.id != this.financing.avalistaPrincipal.id,
        );
      }
    },
    async get() {
      // this.$refs.topProgress.start();
      this.pessoaResponsavelPelaEmpresa = this.$CWLNewPessoaPadrao();

      try {
        const financing = await financingService.findById(this.objId);

        if(this.isProductSubtypeP1) {
          const periodicInformation = await periodicInformationService.getAllOrderByCreate(this.objId);

          let docPeriodicInfo = [];

          periodicInformation.forEach((item) => {
            docPeriodicInfo.push({
              id: item.data().id,
              infoTitle: item.data().infoTitle,
              infoDescription: item.data().infoDescription,
              infoFile: item.data().infoFile,
              infoFileId: item.data().infoFileId,
              created_at: item.data().created_at,
            });
          });

          this.relevantInfos = docPeriodicInfo || [];
        }

        this.cachedFinancing = financing;
        this.financing = {
          ...financing,
          curadoria: financing.curadoria || {},
        };

        const financingAccounts = await financingService.findAccountsById(
          this.objId,
        );

        this.financingAccounts = {
          beneficiary: {},
          platform: {},
          loaded: true,
          ...financingAccounts,
        };

        this.getEstados();

        this.showDebtFields = this.financing.modalidade && this.financing.modalidade.code == 'debt';

        const objImg = {
          name: this.financing.id,
          url: this.financing.imageURL,
          type: 'image',
          size: 333,
        };

        this.fileRecords.push(objImg);

        this.acceptTerm = this.financing.acceptTerm;
        this.isForPJ = this.financing.is_empresa;
        if (this.isForPJ) {
          this.pessoaPJ = this.financing.dados_complementares.empresa;

          this.skipCNPJSearch = true;
          this.cnpjForSearch =
            this.financing.dados_complementares.empresa.documento.numero;
          this.showPJFields = true;

          this.skipCepSearch = true;
          this.cepForSearch =
            this.financing.dados_complementares.empresa.endereco.cep;
          this.showPJAddressFields = true;
          this.pessoaResponsavelPelaEmpresa = this.pessoaPJ.dados_complementares.responsavel;
        }

        // this.$refs.topProgress.done();
        this.content = this.financing.content;
        // this.relevantInformations = this.financing.relevantInformations;

        if (
          this.financing.status == this.CWLStatusCampanha.Reprovada &&
          this.financing.curadoria
        ) {
          setTimeout(
            function () {
              document.getElementById('parsedContent').innerHTML =
                this.financing.curadoria.observacao;
            }.bind(this),
            1000,
          );
        }

        const created_by = this.financing.users.created_by;
        if (created_by) {
          this.criador_da_campanha_nome = created_by.nome;
          this.criador_da_campanha_email = created_by.email
            ? created_by.email.email
            : '';
          this.criador_da_campanha_cpf = created_by.documento
            ? created_by.documento.numero
            : '';
          this.criador_da_campanha_telefone = created_by.telefone
            ? created_by.telefone.telefone
            : '';
          this.criador_da_campanha_nascimento = created_by.dados_complementares
            ? created_by.dados_complementares.birthday
            : '';
        }

        this.publicarAutomaticamente = this.financing.publicar_automaticamente;

        this.getDadosBancarios();
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
        console.log('err', error)
      }
    },

    async getDadosBancarios() {
      if (this.financing.dados_bancarios) {
        this.dados_bancarios.banco = this.financing.dados_bancarios.banco;
        this.dados_bancarios.agencia_numero =
          this.financing.dados_bancarios.agencia_numero;
        this.dados_bancarios.agencia_digito =
          this.financing.dados_bancarios.agencia_digito;
        this.dados_bancarios.conta_numero =
          this.financing.dados_bancarios.conta_numero;
        this.dados_bancarios.conta_digito =
          this.financing.dados_bancarios.conta_digito;
      } else if (
        this.financing.is_empresa &&
        this.financing.dados_complementares.empresa &&
        this.financing.dados_complementares.empresa.dados_bancarios
      ) {
        this.dados_bancarios.banco =
          this.financing.dados_complementares.empresa.dados_bancarios.banco;
        this.dados_bancarios.agencia_numero =
          this.financing.dados_complementares.empresa.dados_bancarios.agencia_numero;
        this.dados_bancarios.agencia_digito =
          this.financing.dados_complementares.empresa.dados_bancarios.agencia_digito;
        this.dados_bancarios.conta_numero =
          this.financing.dados_complementares.empresa.dados_bancarios.conta_numero;
        this.dados_bancarios.conta_digito =
          this.financing.dados_complementares.empresa.dados_bancarios.conta_digito;
      } else if (!this.financing.is_empresa && this.currentPessoa) {
        const pessoaLogadaFirebase = await pessoasService.getPessoa(
          this.currentPessoa.pessoa_id,
        );
        const pessoaLogada = pessoaLogadaFirebase.data();
        if (pessoaLogada.dados_bancarios) {
          this.dados_bancarios.banco = this.pessoaLogada.dados_bancarios.banco;
          this.dados_bancarios.agencia_numero =
            this.pessoaLogada.dados_bancarios.agencia_numero;
          this.dados_bancarios.agencia_digito =
            this.pessoaLogada.dados_bancarios.agencia_digito;
          this.dados_bancarios.conta_numero =
            this.pessoaLogada.dados_bancarios.conta_numero;
          this.dados_bancarios.conta_digito =
            this.pessoaLogada.dados_bancarios.conta_digito;
        }
      }
    },

    async getDynamicFields() {
      this.dynamicFields = [];
      this.$refs.topProgress.start();

      var templateCampos = this.modalidade == 'equity' ? this.modalidade : 'Doação';
      if ( this.financing.modalidade ) {
        templateCampos = this.financing.modalidade.code;
      }
      const dynamicFields = await financingService.getDynamicFields(
        templateCampos,
      );

      dynamicFields.forEach((field) => {
        const newField = field.data();

        if (
          newField.isEditable &&
          !this.allSettings.overwriteMinGlobalInvestment &&
          newField.reference == 'Investimento Mínimo Permitido'
        ) {
          this.financing.fields[newField.reference].value =
            this.allSettings.minGlobalInvestment;
          newField.readonly = true;
        } else {
          if (!this.financing.fields[newField.reference]) {
            this.financing.fields[newField.reference] = newField;
          }
        }

        newField.id = field.id;

        this.dynamicFields.push(newField);
      });

      this.$refs.topProgress.done();
    },

    async getAllFinancingOrders(){
      const AllFinincingOrders = await ordersService.getOrdersByFinancingId(this.financing.id);

      AllFinincingOrders.forEach((local_order) => {
        this.hasAnyPendingOrder = !this.hasAnyPendingOrder ? local_order.status === 'Pendente' : this.hasAnyPendingOrder;
        this.hasAnyPaidOrder = !this.hasAnyPaidOrder ? local_order.status === 'Pago' : this.hasAnyPaidOrder;
        console.log('Order.status', local_order.status);
      });

      this.allOrders = AllFinincingOrders;  
    },

    async getSettings() {
      this.allSettings = await settingsService.getPublicSettings();

      document.getElementById('parsedContentTermosUso').innerHTML =
        this.allSettings.terms.condicoes_termos_uso;

      document.getElementById('parsedContentTaxasPrazo').innerHTML =
        this.allSettings.terms.taxas_prazos;
    },

    async verificarCampanhaProntaParaAnalise() {
      const fieldDinamicoEncerramentoOferta =
        this.financing.fields['Encerramento da Oferta'];
      const fieldDinamicoEncerramentoOfertaValue =
        fieldDinamicoEncerramentoOferta.value;
      const fieldDinamicoValor = this.financing.fields['Valor'];
      const fieldDinamicoValorValue = fieldDinamicoValor.value;

      if (
        this.financing.name != '' &&
        this.financing.categoria != null &&
        fieldDinamicoEncerramentoOfertaValue != null &&
        fieldDinamicoEncerramentoOfertaValue != '' &&
        fieldDinamicoValorValue != null &&
        fieldDinamicoValorValue != '' &&
        this.financing.estado != null &&
        this.financing.cidade != null &&
        this.financing.acceptTerm
      ) {
        this.financing.campanha_pronta_para_analise = true;
      }
    },

    async salvarEnviarParaAnalise() {
      this.save(true);
    },
    async updateCampaignStatus(status) {
      this.$refs.topProgress.start();

      try {
        this.financing.status = status;

        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = this.financing;
        logsService.createNewLog(
          logUser,
          '',
          'Status atualizado pelo gestor: ' + status,
          '',
          logContent,
        );

        await financingService.update(this.financing.id, this.financing);

        this.$refs.topProgress.done();

        this.$notify({
          title: 'Status atualizado com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    async publicarCampanha() {
      const that = this;
      this.$refs.topProgress.start();

      try {
        this.financing.status = CWLStatusCampanha.EmAndamento;
        this.financing.dates.started_at = moment().format(
          'YYYY/MM/DD HH:mm:ss',
        );

        this.financing.users.edited_by = this.currentPessoa;
        this.financing.dates.edited_at = moment().format('YYYY/MM/DD HH:mm:ss');
        const fieldDate = this.financing.fields['Encerramento da Oferta'];

        this.financing.dates.end_at = moment(
          fieldDate.value,
          'YYYY-MM-DD',
        ).format('YYYY/MM/DD');

        var fimPeriodoCaptacaoDate = fieldDate;
        if ( this.financing.fields['fim_periodo_captacao'] ) {
          fimPeriodoCaptacaoDate = this.financing.fields['fim_periodo_captacao'];
        }
        this.financing.dates.fim_periodo_captacao = moment(
            fimPeriodoCaptacaoDate.value,
            'YYYY-MM-DD',
          ).format('YYYY/MM/DD');

        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = this.financing;
        logsService.createNewLog(
          logUser,
          '',
          'Publicar Campanha',
          '',
          logContent,
        );

        await financingService.update(this.objId, this.financing);

        this.$refs.topProgress.done();
        this.$router.push('/financing');
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    update() {
      this.save(false);
    },

    async save(enviarParaAnalise = true) {
      if (this.updating === true) return;

      this.updating = true;
      const that = this;
      this.$refs.topProgress.start();

      try {

        if ( this.modalidade == 'equity' ) {
          if ( this.financing.modalidade && this.financing.modalidade.code == 'debt' ) {
            if ( !this.financing.rentabilidade || this.financing.rentabilidade == '' ) {
              this.$notify({
                title: 'Ooopss',
                message:
                  'Por favor, informe a rentabilidade.',
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'warning',
                timeout: 3000,
              });
              this.$refs.topProgress.done();
              return;
            }
            if ( !this.financing.prazo || this.financing.prazo == '' ) {
              this.$notify({
                title: 'Ooopss',
                message:
                  'Por favor, informe o prazo.',
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'warning',
                timeout: 3000,
              });
              this.$refs.topProgress.done();
              return;
            }



            // if ( !this.financing.fields['Valor'] || !this.financing.fields['Valor'].value ) {
            //   this.$notify({
            //     title: 'Ooopss',
            //     message:
            //       'Por favor, informe o valor.',
            //     icon: 'tim-icons icon-bell-55',
            //     horizontalAlign: 'center',
            //     verticalAlign: 'top',
            //     type: 'warning',
            //     timeout: 3000,
            //   });
            //   this.$refs.topProgress.done();
            //   return;
            // }
          }
        }

        this.financing.users.edited_by = this.currentPessoa;
        this.financing.dates.edited_at = moment().format('YYYY/MM/DD HH:mm:ss');
        const fieldDate = this.financing.fields['Encerramento da Oferta'];
        const initialDate = new Date();
        const finalDate = new Date(fieldDate.value);

        this.financing.dates.end_at = moment(
          fieldDate.value,
          'YYYY-MM-DD',
        ).format('YYYY/MM/DD');

        var fimPeriodoCaptacaoDate = fieldDate;
        if ( this.financing.fields['fim_periodo_captacao'] ) {
          fimPeriodoCaptacaoDate = this.financing.fields['fim_periodo_captacao'];
        }
        this.financing.dates.fim_periodo_captacao = moment(
            fimPeriodoCaptacaoDate.value,
            'YYYY-MM-DD',
          ).format('YYYY/MM/DD');

        if (this.modalidade == 'equity') {
          this.financing.hasMinimalCapture = true;
          const valorCaptacao = this.financing.fields['Valor'];
          this.financing.minimalCapture = (valorCaptacao.value / 3) * 2;
        }

        const diffDays = this.calcDaysBetweenDates(initialDate, finalDate);

        if (diffDays < this.allSettings.maxCampaignDays) {
          this.financing.status = CWLStatusCampanha.EmCriacao;
          this.financing.acceptTerm = true;

          this.verificarCampanhaProntaParaAnalise();

          if (
            enviarParaAnalise &&
            this.financing.campanha_pronta_para_analise
          ) {
            this.financing.status = CWLStatusCampanha.AguardandoAnalise;
          }

          if (this.fileRecords.length == 0) {
            this.financing.imageURL = FinanciamentoPadrao.imageURL;
          }


          /**
           * Dados bancários
           */
          if (this.dados_bancarios.banco) {
            if (!this.financing.dados_bancarios) {
              this.financing.dados_bancarios = {};
            }
            this.financing.dados_bancarios.banco = this.dados_bancarios.banco;
          }
          if (
            this.dados_bancarios.agencia_numero &&
            this.dados_bancarios.agencia_numero.length > 0
          ) {
            if (!this.financing.dados_bancarios) {
              this.financing.dados_bancarios = {};
            }
            this.financing.dados_bancarios.agencia_numero =
              this.dados_bancarios.agencia_numero;
          }
          if (
            this.dados_bancarios.agencia_digito &&
            this.dados_bancarios.agencia_digito.length > 0
          ) {
            if (!this.financing.dados_bancarios) {
              this.financing.dados_bancarios = {};
            }
            this.financing.dados_bancarios.agencia_digito =
              this.dados_bancarios.agencia_digito;
          }
          if (
            this.dados_bancarios.conta_numero &&
            this.dados_bancarios.conta_numero.length > 0
          ) {
            if (!this.financing.dados_bancarios) {
              this.financing.dados_bancarios = {};
            }
            this.financing.dados_bancarios.conta_numero =
              this.dados_bancarios.conta_numero;
          }
          if (
            this.dados_bancarios.conta_digito &&
            this.dados_bancarios.conta_digito.length > 0
          ) {
            if (!this.financing.dados_bancarios) {
              this.financing.dados_bancarios = {};
            }
            this.financing.dados_bancarios.conta_digito =
              this.dados_bancarios.conta_digito;
          }

          if (this.financing.dados_bancarios) {
            if (this.isForPJ && !this.pessoaPJ.dados_bancarios) {
              this.pessoaPJ.dados_bancarios = this.financing.dados_bancarios;
            } else if (!this.isForPJ && this.currentPessoa) {
              const pessoaLogadaFirebase = await pessoasService.getPessoa(
                this.currentPessoa.pessoa_id,
              );
              const pessoaLogada = pessoaLogadaFirebase.data();
              if (!pessoaLogada.dados_bancarios) {
                pessoaLogada.dados_bancarios = this.financing.dados_bancarios;
                await pessoasService.updatePessoa(
                  pessoaLogadaFirebase.id,
                  pessoaLogada,
                );
              }
            }
          }

          /**
           * Campanha para PJ
           */
          this.financing.is_empresa = this.isForPJ ? this.isForPJ : false;
          if (this.financing.is_empresa) {
            const pessoa = await pessoasService.criarPessoaSemValidacao(this.pessoaResponsavelPelaEmpresa);
            const pessoaAtualizada = await pessoasService.updateCompany(
              this.pessoaPJ,
              {
                responsavel: pessoa,
              },
            );
            this.financing.dados_complementares.empresa = pessoaAtualizada;
          }

          /**
           * Publicar automaticamente
           */
          this.financing.publicar_automaticamente =
            this.publicarAutomaticamente;

          /**
           * Avalistas
           */

          const avalistasToSave = [];

          if (this.financing.avalistaPrincipal) {
            avalistasToSave.push(this.financing.avalistaPrincipal.id);
          }

          if (this.financing.demaisAvalistas) {
            this.financing.demaisAvalistas.forEach((avalista) => {
              avalistasToSave.push(avalista.id);
            });
          }

          this.financing.avalistas_oferta = avalistasToSave;

          const logUser = {
            user: {
              email: this.currentPessoa.email.email,
            },
          };
          const logContent = this.financing;
          logsService.createNewLog(
            logUser,
            '',
            'Atualizar Campanha',
            '',
            logContent,
          );

          await financingService.update(this.objId, this.financing);

          /**
           * Subconta
           */
          let objWS = {};
          if (this.financing.is_empresa) {
            objWS = {
              nome: this.pessoaPJ.nome,
              nomeComercial: this.pessoaPJ.dados_complementares.nome_fantasia,
              documento: this.pessoaPJ.documento.numero
                .replace('.', '')
                .replace('.', '')
                .replace('.', '')
                .replace('-', ''),
              email: this.currentPessoa.email.email,
              nomeResponsavel: this.currentPessoa.nome,
              documentoResponsavel: this.currentPessoa.documento.numero
                .replace('.', '')
                .replace('.', '')
                .replace('-', ''),
              financingId: this.objId,
            };
          } else {
            objWS = {
              nome: this.currentPessoa.nome,
              nomeComercial: this.currentPessoa.nome,
              documento: this.currentPessoa.documento.numero
                .replace('.', '')
                .replace('.', '')
                .replace('-', ''),
              email: this.currentPessoa.email.email,
              financingId: this.objId,
            };
          }
          if (
            !this.financing.hasMinimalCapture &&
            this.dados_bancarios.banco &&
            this.dados_bancarios.banco.code &&
            this.dados_bancarios.agencia_numero &&
            this.dados_bancarios.conta_numero &&
            this.dados_bancarios.conta_digito
          ) {
            objWS.codigo_banco = this.dados_bancarios.banco.code;
            objWS.agencia_numero = this.dados_bancarios.agencia_numero;
            objWS.agencia_digito = this.dados_bancarios.agencia_digito;
            objWS.conta_numero = this.dados_bancarios.conta_numero;
            objWS.conta_digito = this.dados_bancarios.conta_digito;
          }
          objWS.financingSubaccountId = this.financingAccounts.beneficiary.id;
          await this.$CWLupdateSubconta(
            objWS,
            !this.financing.hasMinimalCapture,
          );

          if (
            enviarParaAnalise &&
            !this.financing.campanha_pronta_para_analise
          ) {
            that.$refs.topProgress.done();
            that.$notify({
              title: 'Atualizada com sucesso!',
              message:
                'Porém, não foi possível enviar para análise, pois alguns campos obrigatórios não foram preenchidos.',
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning',
              timeout: 3000,
            });
            that.$router.push('/financing');

            return;
          }

          if (
            this.fileRecords.length > 0 &&
            this.fileRecords[0].url != this.financing.imageURL
          ) {
            const file = this.fileRecords[0];
            attachmentsService
              .addNewFinancingImageAttachment(
                file.file,
                file.file.name,
                'Financings',
                this.financing.id,
              )
              .then(async function (financingDocumentCreated) {
                const bucket = 'Financings/' + that.financing.id;
                that.financing.imageURL =
                  await attachmentsService.urlAttachment(
                    financingDocumentCreated.ref.name,
                    bucket,
                  );

                await financingService.update(
                  that.financing.id,
                  that.financing,
                );

                if (enviarParaAnalise) {
                  that.$refs.topProgress.done();
                  that.$router.push('/resume-financing/' + that.financing.id);
                } else {
                  that.$notify({
                    title: 'A campanha está pronta para ser enviada!',
                    icon: 'tim-icons icon-bell-55',
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'success',
                    timeout: 3000,
                  });

                  that.$refs.topProgress.done();
                  that.financing = that.$CWLNewFinanciamentoPadrao();
                  that.$router.push('/financing');
                }
              });
          } else {
            if (enviarParaAnalise) {
              that.$refs.topProgress.done();
              that.$router.push('/resume-financing/' + that.financing.id);
            } else {
              that.$notify({
                title: 'A campanha está pronta para ser enviada!',
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'success',
                timeout: 3000,
              });

              that.$refs.topProgress.done();
              that.financing = that.$CWLNewFinanciamentoPadrao();
              that.$router.push('/financing');
            }
          }
        } else {
          this.$notify({
            title: 'Ooopss',
            message:
              'Você não pode criar com mais de ' +
              this.allSettings.maxCampaignDays +
              ' dias',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 3000,
          });

          this.$refs.topProgress.done();
        }
      } catch (error) {
        this.updating = false;
        console.error(error);
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
        that.$refs.topProgress.done();
      }
      if (this.uploading !== false) {
        setTimeout(() => {
          this.uploading = false;
        }, 5000);
      }
    },
    async getEstados() {
      this.estados = municipios.estados.map((e) => ({
        name: e.nome,
        uf: e.sigla,
      }));

      if (this.financing.estado) {
        const ufSelected = this.estados.find(
          (e) => e.uf === this.financing.estado.uf,
        );
        this.setEstadoSelected(ufSelected, false);
      }

      if (
        this.financing.is_empresa &&
        this.financing.dados_complementares.empresa &&
        this.financing.dados_complementares.empresa.endereco.estado
      ) {
        const empresaUf =
          this.financing.dados_complementares.empresa.endereco.estado;

        const ufSelected = this.estados.find(
          (e) => e.uf === empresaUf || e.uf == empresaUf.uf,
        );
        this.setPJEstadoSelected(ufSelected, false);
      }
    },

    async getCategorias() {
      const that = this;

      await withLog(() =>
        collectionRef('categorias').orderBy('name', 'asc').get(),
      ).then((querySnapshot) => {
        querySnapshot.forEach((categoria) => {
          const obj = categoria.data();
          obj.id = categoria.id;
          that.categorias.push(obj);
        });
      });
    },

    async getBancarizadores() {
      this.bancarizadores = (await bancarizadoresService.getAll().catch((e) => [])) || [];
    },

    async getAvalistas() {
      const allAvalistas = await pessoasService.getAllAvalistas();
      this.avalistas = allAvalistas.map((item) => ({
        name: item.nome,
        id: item.id,
      })) || [];

    },

    async getBancos() {
      const that = this;

      await collectionRef('banks')
        .orderBy('name', 'asc')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((banco) => {
            const obj = banco.data();
            obj.id = banco.id;
            that.bancos.push(obj);
          });
        })
        .catch((error) => console.error('error', error));
    },

    checkAcceptTerms() {
      this.$refs.accept_term.click();
    },

    openUseTerms() {
      this.showModalTermos = true;
    },

    openTaxasPrazos() {
      this.showModalTaxasPrazos = true;
    },

    setEstadoSelected(value, skipResetFinancingCidade = true) {
      this.cidades = [];
      if (skipResetFinancingCidade) {
        this.financing.cidade = null;
      }
      this.isLoadingCidades = true;
      if (!value) {
        this.isLoadingCidades = false;
        return;
      }

      const estado = municipios.estados.find((e) => e.sigla === value.uf);
      this.cidades = estado ? estado.cidades : [];
      this.isLoadingCidades = false;
    },

    setPJEstadoSelected(value, skipResetFinancingCidade = true) {
      this.cidades = [];
      if (skipResetFinancingCidade) {
        this.pessoaPJ.endereco.cidade = null;
      }
      this.isLoadingPJCidades = true;
      if (!value) {
        this.isLoadingPJCidades = false;
        return;
      }

      const estado = municipios.estados.find((e) => e.sigla === value.uf);
      this.cidades = estado ? estado.cidades : [];
      this.isLoadingPJCidades = false;
    },

    onBeforeDelete(fileRecord) {
      const i = this.fileRecords.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecords.splice(i, 1);
      }
    },

    checkIsForPJ() {
      this.blockByPJError = this.isForPJ;

      if (this.isForPJ) {
        this.textoDadosBancarios =
          'Para podermos realizar a remessa dos valores, a empresa deve ser a titular da conta abaixo';
      } else {
        this.textoDadosBancarios =
          'Para podermos realizar a remessa dos valores, você deve ser o titular da conta abaixo';
      }
    },

    handleBankFormTextDescription(isCompany) {
      if (isCompany) {
        this.textoDadosBancarios =
          'Para podermos realizar a remessa dos valores, a empresa deve ser a titular da conta abaixo';
      } else {
        this.textoDadosBancarios =
          'Para podermos realizar a remessa dos valores, você deve ser o titular da conta abaixo';
      }
    },

    async changeCNPJ(cnpj) {
      const that = this;

      const cnpjWithoutMask = cnpj
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', '');
      if (cnpjWithoutMask.length == 14 && !this.skipCNPJSearch) {
        this.showPJFields = false;

        if (!this.isLoadingPessoaPJ) {
          this.isLoadingPessoaPJ = true;
          this.initPessoaPJ();

          await pessoasService
            .searchPJByCNPJ(cnpj)
            .then(async function (document) {
              if (document.size == 0) {
                const externalPessoaFound =
                  await pessoasService.searchExternalPJByCNPJ(cnpj);

                if (externalPessoaFound) {
                  that.pessoaPJ = externalPessoaFound;
                  that.pessoaPJ.dados_complementares.responsavel = that.pessoaResponsavelPelaEmpresa;
                } else {
                  that.cepForSearch = '';
                }

                if (that.pessoaPJ.endereco.cep != '') {
                  that.skipCepSearch = true;
                  that.cepForSearch = that.pessoaPJ.endereco.cep;
                  that.showPJAddressFields = true;
                } else {
                  that.showPJAddressFields = false;
                }

                that.blockByPJError = false;
                that.showPJFields = true;
                that.isLoadingPessoaPJ = false;
              } else {
                document.forEach(function (pessoaFoundDocument) {
                  const pessoaFound = pessoaFoundDocument.data();
                  pessoaFound.id = pessoaFoundDocument.id;

                  if (
                    pessoaFound.dados_complementares.responsavel &&
                    store.getters.getPessoa
                  ) {
                    if (
                      pessoaFound.dados_complementares.responsavel.email
                        .email// == store.getters.getPessoa.email.email -- COMENTADO, POIS ESTA REGRA VAI DAR PROBLEMA NO FLUXO DO GESTOR REVISAR A CAMPANHA == store.getters.getPessoa.email.email
                    ) {
                      that.pessoaPJ = pessoaFound;

                      if (that.pessoaPJ.endereco.cep != '') {
                        that.skipCepSearch = true;
                        that.cepForSearch = that.pessoaPJ.endereco.cep;
                        that.showPJAddressFields = true;
                      } else {
                        that.showPJAddressFields = false;
                      }

                      that.blockByPJError = false;
                      that.showPJFields = true;
                      that.isLoadingPessoaPJ = false;
                    } else {
                      that.$notify({
                        title: 'Ooopss',
                        message:
                          'Não foi possível realizar a criação para esta empresa privada ou entidade sem fins lucrativos. Por favor, entre em contato com a plataforma ou desmarque esta opção.',
                        icon: 'tim-icons icon-bell-55',
                        horizontalAlign: 'center',
                        verticalAlign: 'top',
                        type: 'warning',
                        timeout: 3000,
                      });
                      that.blockByPJError = true;
                      that.isLoadingPessoaPJ = false;
                    }
                  } else {
                    that.$notify({
                      title: 'Ooopss',
                      message:
                        'Não foi possível realizar a criação para esta empresa privada ou entidade sem fins lucrativos. Por favor, entre em contato com a plataforma ou desmarque esta opção.',
                      icon: 'tim-icons icon-bell-55',
                      horizontalAlign: 'center',
                      verticalAlign: 'top',
                      type: 'warning',
                      timeout: 3000,
                    });
                    that.blockByPJError = true;
                    that.isLoadingPessoaPJ = false;
                  }
                });
              }
            });
        }
      } else if (!this.isLoadingPessoaPJ && !this.skipCNPJSearch) {
        this.showPJFields = false;
        this.blockByPJError = true;

        this.pessoaPJ.nome = '';
        this.pessoaPJ.endereco = {};
        this.pessoaPJ.dados_complementares = {};
      }
      this.skipCNPJSearch = false;
    },

    async changeCEP(cep) {
      const cepWithoutMask = cep
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', '');
      if (cepWithoutMask.length == 8 && !this.skipCepSearch) {
        this.showPJAddressFields = false;

        if (!this.isLoadingEnderecoPessoaPJ) {
          this.isLoadingEnderecoPessoaPJ = true;
          this.pessoaPJ.endereco = Object.assign({}, EnderecoPadrao);

          const enderecoFound = await pessoasService.searchAddressByZipcode(
            cep,
          );
          if (enderecoFound) {
            this.pessoaPJ.endereco = enderecoFound;
          }

          this.showPJAddressFields = true;
          this.isLoadingEnderecoPessoaPJ = false;
        }
      } else if (!this.isLoadingEnderecoPessoaPJ && !this.skipCepSearch) {
        this.showPJAddressFields = false;
        this.pessoaPJ.endereco = {};
      }
      this.skipCepSearch = false;
    },

    initPessoaPJ() {
      this.pessoaPJ = this.$CWLNewPessoaPadraoPJ();
    },

    checkPublicarAutomaticamente() {
      this.$refs.publicar_automaticamente.click();
    },

    async aprovarCampanha() {
      const that = this;
      this.$refs.topProgress.start();

      try {
        this.financing.status = CWLStatusCampanha.Aprovada;
        this.financing.users.approved_by = this.currentPessoa;
        this.financing.dates.approved_at = moment().format(
          'YYYY/MM/DD HH:mm:ss',
        );

        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = this.financing;
        logsService.createNewLog(
          logUser,
          '',
          'Aprovar Campanha',
          '',
          logContent,
        );

        await financingService.update(this.objId, this.financing);
        const objMailDeprecated = {
          email: this.financing.users.created_by.email.email,
          name: this.financing.users.created_by.nome,
          campanha_nome: this.financing.name,
          campanha_link: process.env.VUE_APP_ROOT_SITE + '/projetos',
        };
        const objMail = {
          financingId: this.financing.id
        };
        this.$CWLsendMailAprovarCampanha(objMail).catch((error) =>
          console.error('erro no envio de email de aprovacao', error),
        );

        this.$notify({
          title: 'Aprovada com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
        this.$router.push('/financing');
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },
    async reprovarCampanha() {
      const that = this;
      this.$refs.topProgress.start();

      try {
        this.financing.status = CWLStatusCampanha.Reprovada;
        this.financing.users.reproved_by = this.currentPessoa;
        this.financing.dates.reproved_at = moment().format(
          'YYYY/MM/DD HH:mm:ss',
        );

        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = this.financing;
        logsService.createNewLog(
          logUser,
          '',
          'Reprovar Campanha',
          '',
          logContent,
        );

        await financingService.update(this.objId, this.financing);
        const objMailDeprecated = {
          email: this.financing.users.created_by.email.email,
          name: this.financing.users.created_by.nome,
          campanha_nome: this.financing.name,
          campanha_link: process.env.VUE_APP_ROOT_SITE + '/projetos',
        };
        const objMail = {
          financingId: this.financing.id
        };
        await this.$CWLsendMailReprovarCampanha(objMail);

        this.$notify({
          title: 'Reprovada!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
        this.$router.push('/financing');
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    async salvarDadosBancarios() {
      this.$refs.topProgress.start();
      /**
       * Dados bancários
       */
      if (this.dados_bancarios.banco) {
        if (!this.financing.dados_bancarios) {
          this.financing.dados_bancarios = {};
        }
        this.financing.dados_bancarios.banco = this.dados_bancarios.banco;
      }
      if (
        this.dados_bancarios.agencia_numero &&
        this.dados_bancarios.agencia_numero.length > 0
      ) {
        if (!this.financing.dados_bancarios) {
          this.financing.dados_bancarios = {};
        }
        this.financing.dados_bancarios.agencia_numero =
          this.dados_bancarios.agencia_numero;
      }
      if (
        this.dados_bancarios.agencia_digito &&
        this.dados_bancarios.agencia_digito.length > 0
      ) {
        if (!this.financing.dados_bancarios) {
          this.financing.dados_bancarios = {};
        }
        this.financing.dados_bancarios.agencia_digito =
          this.dados_bancarios.agencia_digito;
      }
      if (
        this.dados_bancarios.conta_numero &&
        this.dados_bancarios.conta_numero.length > 0
      ) {
        if (!this.financing.dados_bancarios) {
          this.financing.dados_bancarios = {};
        }
        this.financing.dados_bancarios.conta_numero =
          this.dados_bancarios.conta_numero;
      }
      if (
        this.dados_bancarios.conta_digito &&
        this.dados_bancarios.conta_digito.length > 0
      ) {
        if (!this.financing.dados_bancarios) {
          this.financing.dados_bancarios = {};
        }
        this.financing.dados_bancarios.conta_digito =
          this.dados_bancarios.conta_digito;
      }

      if (this.financing.dados_bancarios) {
        if (this.isForPJ && !this.pessoaPJ.dados_bancarios) {
          this.pessoaPJ.dados_bancarios = this.financing.dados_bancarios;
        } else if (!this.isForPJ && this.currentPessoa) {
          const pessoaLogadaFirebase = await pessoasService.getPessoa(
            this.currentPessoa.pessoa_id,
          );
          const pessoaLogada = pessoaLogadaFirebase.data();
          if (!pessoaLogada.dados_bancarios) {
            pessoaLogada.dados_bancarios = this.financing.dados_bancarios;
            await pessoasService.updatePessoa(
              pessoaLogadaFirebase.id,
              pessoaLogada,
            );
          }
        }
      }

      /**
       * Campanha para PJ
       */
      this.financing.is_empresa = this.isForPJ ? this.isForPJ : false;
      if (this.financing.is_empresa) {
        const pessoa = store.getters.getPessoa;
        const pessoaAtualizada = await pessoasService.updateCompany(
          this.pessoaPJ,
          {
            responsavel: pessoa,
          },
        );
        this.financing.dados_complementares.empresa = pessoaAtualizada;
      }

      await financingService.update(this.objId, this.financing);

      /**
       * Subconta
       */
      let objWS = {};
      if (this.financing.is_empresa) {
        objWS = {
          nome: this.pessoaPJ.nome,
          nomeComercial: this.pessoaPJ.dados_complementares.nome_fantasia,
          documento: this.pessoaPJ.documento.numero
            .replace('.', '')
            .replace('.', '')
            .replace('.', '')
            .replace('-', ''),
          email: this.currentPessoa.email.email,
          nomeResponsavel: this.currentPessoa.nome,
          documentoResponsavel: this.currentPessoa.documento.numero
            .replace('.', '')
            .replace('.', '')
            .replace('-', ''),
          financingId: this.objId,
        };
      } else {
        objWS = {
          nome: this.currentPessoa.nome,
          nomeComercial: this.currentPessoa.nome,
          documento: this.currentPessoa.documento.numero
            .replace('.', '')
            .replace('.', '')
            .replace('-', ''),
          email: this.currentPessoa.email.email,
          financingId: this.objId,
        };
      }
      if (
        !this.financing.hasMinimalCapture &&
        this.dados_bancarios.banco &&
        this.dados_bancarios.banco.code &&
        this.dados_bancarios.agencia_numero &&
        this.dados_bancarios.conta_numero &&
        this.dados_bancarios.conta_digito
      ) {
        objWS.codigo_banco = this.dados_bancarios.banco.code;
        objWS.agencia_numero = this.dados_bancarios.agencia_numero;
        objWS.agencia_digito = this.dados_bancarios.agencia_digito;
        objWS.conta_numero = this.dados_bancarios.conta_numero;
        objWS.conta_digito = this.dados_bancarios.conta_digito;
      }
      objWS.financingSubaccountId = this.financingAccounts.beneficiary.id;
      await this.$CWLupdateSubconta(objWS, !this.financing.hasMinimalCapture);

      this.$notify({
        title: 'Dados bancários atualizados!',
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type: 'success',
        timeout: 3000,
      });
      this.$refs.topProgress.done();
    },

    verTransacoesAgenteSocial() {
      this.$router.push('/transactions/social-agent/' + this.objId);
    },

    verTransacoesCWL() {
      this.$router.push('/transactions/cwl/' + this.objId);
    },

    verTransacoesCampanha() {
      this.$router.push('/transactions/campanha/' + this.objId);
    },

    verTransacoesMarketplace() {
      this.$router.push('/transactions/marketplace/' + this.objId);
    },

    verForum() {
      this.$router.push('/campaign-details/' + this.financing.id);
    },

    async initTour() {
      if (!this.currentPessoa.disableTour) {
        this.$tours['myTour'].start();

        const pessoaLogadaFirebase = await pessoasService.getPessoa(
          this.currentPessoa.pessoa_id,
        );
        const pessoaLogada = pessoaLogadaFirebase.data();
        pessoaLogada.id = pessoaLogadaFirebase.id;
        pessoaLogada.disableTour = true;
        await pessoasService.updatePessoa(
          pessoaLogadaFirebase.id,
          pessoaLogada,
        );

        const objCooke = this.$CWLCreatePessoaForCookie(pessoaLogada);
        this.$store.commit('SET_PESSOA', objCooke);
      }
    },

    async salvarBalancete() {
      this.$refs.topProgress.start();

      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = this.financing;
        logsService.createNewLog(
          logUser,
          '',
          'Salvar Balancete',
          '',
          logContent,
        );

        await financingService.update(this.financing.id, this.financing);

        this.$refs.topProgress.done();

        this.$notify({
          title:
            'Plano de Aplicação/Prestação de Contas atualizado com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    setModalidadeSelected(obj) {
      if ( obj ) {
        this.showDebtFields = obj.code == 'debt';
      }
      this.getDynamicFields();
    },

    formattedSecondstoDate(timestamp) {
      const value = new Date(timestamp.seconds*1000);
      return moment(value).format('LLL');
    },

    async salvarInformacoesRelevantes() {
      // this.$refs.topProgress.start();

      const file = await this.$refs.uploadFile.files[0];
      var email = this.currentPessoa.email.email;
      if ( this.pessoaId ) {
        let pessoa = await pessoasService.findPessoaById(this.pessoaId);
        email = pessoa.email.email;
      }

      try {
        const randonNumber = Math.floor(Math.random() * 20)
        const periodicInfo =  {
          id: randonNumber,
          infoTitle: this.infoTitle,
          created_at: moment(new Date()).format('LLL'),
          infoDescription: this.infoDescription === '' ? '' : this.infoDescription,
          infoFile: this.file.selectedFilename,
          financing_id: this.objId,
          infoFileId: this.file.selectedFilename ? randonNumber: ''
        };

        const logUser = {
            user: {
                email: email
            }
        };

        const logContent = this.file.selectedFilename;
        logsService.createNewLog(logUser, '', 'Adicionar Documento PDF', '', logContent);

        if(this.file.selectedFilename) {
          await periodicInformationService.addNewPeriodicInforamtionImage(file, this.file.selectedFilename, 'PeriodicInformations', this.objId);
        }
        this.relevantInfos.unshift(periodicInfo);
        await periodicInformationService.create(periodicInfo);

        this.$emit('uploadDocument', 'IdentificationDocument');

        this.created_at= '',
        this.infoTitle='',
        this.infoDescription= '',
        this.infoFile= '',
        this.file.selectedFilename='',
        this.file.selectedFile= '',
        this.file.randonNameFile='',
        this.file.infoFileId='',

        console.log('file', this.file)

        this.$notify({
          title:
            'Informações Relevantes atualizado com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        console.log('err', error)
        // this.$refs.topProgress.done();
      }
    },

    async deletarInformacoesRelevantes(resp) {
      try {
        periodicInformationService.delete(resp.id);
        this.relevantInfos = this.relevantInfos.filter(item => item !== resp);

        this.$notify({
          title:
            'Informação deletada com sucesso',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro ao deletar.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
        console.log('err', error)
      }
    },

    async downloadPDF(file) {
      try {
        const financing = await financingService.findById(this.objId);
        const path = 'PeriodicInformations/' + financing.id;
        await periodicInformationService.downloadPeriodicInformation(file, path);
      }
      catch (e) {
        console.log('Erro ao fazer download do pdf', e);
      }
    },

    selectedFile() {
      this.file.selectedFilename = 'id' + (new Date()).getTime();
    },

    generatePdf() {
      const options = {
        headElements: [],
      };

      console.log(options)

      const d = new Printd(options);
      d.print(document.getElementById('myelement'));
    },

    closeModal() {
      this.showConfirmarInvestimentoModal = false;
      this.showExcluirOfertaModal = false;
      this.operacaoErrorMessage = "";
      this.operacaoErrorShown = false;
    },

    async preConfirmarOperacaoInvestimento() {
      this.showConfirmarInvestimentoModal = true;
      this.showConfirmarInvestimentoModalHeader = true;

      /**
       * Get orders
       */
      this.orders = [];

      const ordersFromService = await ordersService.getOrdersByFinancingId(
        this.financing.id,
      );
      ordersFromService.forEach( function(order) {
        if ( !order.ccb || !order.ccb.importacao ) {
          if ( !order.ccb ) {
            order.ccb = {
              importacao: {
                status: "pendente"
              }
            };
          }
          else if ( !order.ccb.importacao ) {
            order.ccb.importacao = {
              status: "pendente"
            };
          }
        }
      });
      this.orders = ordersFromService;
    },

    async downloadContratoCCB(order) {

      try {
        let filename = order.ccb.numero_ccb + "_ContratoCCB.pdf";
        await financingService.downloadFinancing(filename, 'ContratosCCB');
      }
      catch (e) {
        console.log(e);
      }
    },

    async importarDocumentos(order) {
      if ( !order.ccb.importacao_documentos ) {
        order.ccb.importacao_documentos = {};
      }

      order.ccb.importacao_documentos.status = "executando";

      const objeto = {
        financing: this.financing,
        order: order
      };

      await fiduciaService
        .importarDocumentos(objeto)
        .then(function (result) {
          const dados = result.resposta;
          order.ccb.importacao_documentos.operacao = dados;
          order.ccb.importacao_documentos.status = "importado";

          ordersService.update(order.id, order);
        })
        .catch(function (error) {
          order.ccb.importacao_documentos.status = "erro";
          order.ccb.importacao_documentos.error_message = error.message;
          ordersService.update(order.id, order);
        });
    },

    async complementarEmpresa() {
      this.$router.push('/edit-financing/' + this.objId + "/fill-company");
    },

    async validarQualFechamentoOperacaoChamar() {
      this.$router.push('/edit-financing/' + this.objId + "/confirm-operations");
      return;
      if ( this.financing.bancarizador && this.financing.bancarizador.integracao_id == BancarizadorIntegracaoID.Socinal ) {
        this.$router.push('/edit-financing/' + this.objId + "/confirm-operations");
      }
      else {
        this.preConfirmarOperacaoInvestimento();
      }
    },

    async confirmarOperacaoInvestimento() {
      const that = this;
      let hasOrderPaid = this.orders.filter(order => {
        return order.status == 'Pago';
      });
      let ordersParaProcessar = this.orders.filter(order => {
        return order.status == 'Pago' && order.ccb && order.ccb.importacao && ( order.ccb.importacao.status == "erro" || order.ccb.importacao.status == "pendente" );
      }).length;
      if ( !hasOrderPaid.length ) {
        this.operacaoErrorMessage = "Não existem aportes com o status 'Pago' para efetuar a operação.";
        this.operacaoErrorShown = true;
        return;
      }
      if ( !ordersParaProcessar ) {
        this.operacaoErrorMessage = "Não existem aportes para processar.";
        this.operacaoErrorShown = true;
        return;
      }

      this.showConfirmarInvestimentoModalHeader = false;
      this.operacaoEmAndamento = true;
      this.operacaoConcluida = false;
      var ordersProcessadas = 0;
      this.orders.forEach(function(order) {
        var importarOperacao = false;
        if ( !order.ccb ) {
          order.ccb = {
            importacao: {
              status: "erro",
              error_message: "Este aporte não possui número CCB."
            }
          };
          ordersService.update(order.id, order);
        }
        else if ( !order.ccb.numero_ccb ) {
          order.ccb.importacao = {
            status: "erro",
            error_message: "Este aporte não possui número CCB."
          };
          ordersService.update(order.id, order);
        }
        else if ( !order.ccb.simulacao ) {
          order.ccb.importacao = {
            status: "erro",
            error_message: "Este aporte não possui simulação."
          };
          ordersService.update(order.id, order);
        }
        else if ( !order.ccb.importacao || order.ccb.importacao.status == "pendente" ) {
          importarOperacao = true;
          order.ccb.importacao = {
            status: "pendente"
          };
          ordersService.update(order.id, order);
        }
        else if ( order.ccb.importacao && order.ccb.importacao.status == "erro" ) {
          importarOperacao = true;
        }

        const objeto = {
          financing: that.financing,
          order: order
        };
        if ( order.status == 'Pago' && order.ccb && order.ccb.importacao && importarOperacao ) {
          order.ccb.importacao.status = "executando";
          fiduciaService
            .criarOperacaoCredito(objeto)
            .then(async function (result) {
              const dados = result.resposta;
              order.ccb.importacao.operacao = dados;
              order.ccb.importacao.status = "importado";

              ordersService.update(order.id, order);

              await that.importarDocumentos(order);
              ordersProcessadas++;
              that.operacaoConcluida = ordersProcessadas == ordersParaProcessar;
              that.operacaoEmAndamento = ordersProcessadas != ordersParaProcessar;
            })
            .catch(function (error) {
              order.ccb.importacao.status = "erro";
              order.ccb.importacao.error_message = error.message;
              ordersService.update(order.id, order);
              ordersProcessadas++;
              that.operacaoConcluida = ordersProcessadas == ordersParaProcessar;
              that.operacaoEmAndamento = ordersProcessadas != ordersParaProcessar;
            });
        }
      });
    },

    formatDate(date) {
      // return moment(date).tz('America/Sao_Paulo').format('DD/MM/YYYY')
      return this.$CWLFormatDate(date);
    },

    importacaoStatus(order) {
      return ( order.ccb && order.ccb.importacao && order.ccb.importacao.status ) ? order.ccb.importacao.status : "pendente";
    },

    importacaoDocumentosStatus(order) {
      const ccb = order.ccb;
      if ( !ccb.importacao || !ccb.importacao.operacao || !ccb.importacao.operacao.operacao || !ccb.importacao.operacao.operacao.operacao ) {
        return "pendente_operacao";
      }
      else {
        return ( order.ccb && order.ccb.importacao_documentos && order.ccb.importacao_documentos.status ) ? order.ccb.importacao_documentos.status : "pendente";
      }
    },

    importacaoErrorMessage(order) {
      return ( order.ccb && order.ccb.importacao && order.ccb.importacao.error_message ) ? order.ccb.importacao.error_message : "Erro Genérico";
    },

    importacaoDocumentosErrorMessage(order) {
      return ( order.ccb && order.ccb.importacao_documentos && order.ccb.importacao_documentos.error_message ) ? order.ccb.importacao_documentos.error_message : "Erro Genérico";
    },

    contratoPersonalizadoChecked(value) {
      this.financing.possui_contrato_personalizado = value;
      this.editorContratoPersonalizado.enable(value);

      this.contrato_personalizado_content = '';
      this.financing.contrato_personalizado = '';
      this.editorContratoPersonalizado.setText('');
    },

    async salvarContratoPersonalizado() {
      this.$refs.topProgress.start();

      try {
        const logUser = {
          user: {
            email: this.currentPessoa.email.email,
          },
        };
        const logContent = this.financing;
        logsService.createNewLog(
          logUser,
          '',
          'Salvar Contrato Personalizado',
          '',
          logContent,
        );

        await financingService.update(this.financing.id, this.financing);

        this.$refs.topProgress.done();

        this.$notify({
          title:
            'Contrato Personalizado atualizado com sucesso!',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });
      } catch (error) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });

        this.$refs.topProgress.done();
      }
    },

    async recriarSubconta() {
      this.$refs.topProgress.start();

      await this.$CWLcreateSubconta(this.financing.id);

      this.$refs.topProgress.done();

      this.$router.go();
    },

    async preConfirmarExclusaoOferta() {
      const ordersFromService = await ordersService.getOrdersByFinancingId(
        this.financing.id,
      );

      if ( ordersFromService.length > 0 ) {
        this.$notify({
          title: 'Ooopss',
          message: 'Esta oferta já possui investimentos. Não é possível excluí-la.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
      else {
        this.showExcluirOfertaModal = true;
      }
    },

    async confirmarExclusaoOferta() {
      await this.updateCampaignStatus(CWLStatusCampanha.Excluida);
      this.showExcluirOfertaModal = false;
    },

    async habilitarRepasse() {
      this.$refs.topProgress.start();
      this.isLoadingButtonDisabled = true;

      try {
        this.financing.repasse_habilitado = true;
        await financingService.update(this.financing.id, this.financing);
        await financingService.atualizarRepasseRecebiveis(this.financing.id);
      }
      catch (e) {
        console.log(e);
        this.financing.repasse_habilitado = false;
        await financingService.update(this.financing.id, this.financing);
        this.$notify({
          title: 'Ooopss',
          message: 'Ocorreu um erro ao habilitar o repasse de recebíveis. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
      this.$refs.topProgress.done();
      this.isLoadingButtonDisabled = false;
    },

    async desabilitarRepasse() {
      this.$refs.topProgress.start();
      this.isLoadingButtonDisabled = true;

      try {
        this.financing.repasse_habilitado = false;
        await financingService.update(this.financing.id, this.financing);
        await financingService.atualizarRepasseRecebiveis(this.financing.id);
      }
      catch (e) {
        this.financing.repasse_habilitado = true;
        await financingService.update(this.financing.id, this.financing);
        this.$notify({
          title: 'Ooopss',
          message: 'Ocorreu um erro ao desabilitar o repasse de recebíveis. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }
      this.$refs.topProgress.done();
      this.isLoadingButtonDisabled = false;
    },
  },
};
</script>

<template>
  <div class="add-user" id="user-content">
    <card
      v-if="financingAccounts.loaded &&
        (!financingAccounts.beneficiary.id || !financingAccounts.platform.id) &&
        isAdminOrGestorOrCurador
      "
      style="text-align: center; background-color: #288cf7; color: white"
    >
      <b
        >Esta campanha está sem token de integração. Por favor, entre em contato
        com CWL.</b
      >
      <div v-if="isAdmin">
        <br>
        <button class="btn btn-warning" @click="recriarSubconta">
          Obter token de integração
        </button>
      </div>
    </card>
    <card>
      <form @submit.prevent="update()">
        <h5 slot="header" class="title" v-if="modalidade == 'doacao'">
          <router-link
            to="/financing"
            class="fa fa-arrow-left"
            title="Voltar"
          ></router-link>
          Ver campanha
        </h5>

        <h5 slot="header" class="title" v-if="modalidade == 'equity'">
          <router-link
            to="/financing"
            class="fa fa-arrow-left"
            title="Voltar"
          ></router-link>
          Voltar para ofertas
        </h5>

        <div class="row" v-if="modalidade == 'equity'">
          <div class="col-md-6">
            <base-input required label="Modalidade*">
              <v-select
                label="name"
                class="style-chooser"
                :options="modalidades"
                v-model="financing.modalidade"
                :components="{ Deselect }"
                @input="setModalidadeSelected"
                disabled
              ></v-select>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <base-input
              id="v-step-0"
              label="Nome*"
              placeholder="Ex: Ajuda para novos leitos"
              v-model="financing.name"
              required
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
              "
            ></base-input>
          </div>

          <div class="col-md-3">
            <base-input required label="Categoria*" class="v-step-1">
              <v-select
                label="name"
                class="style-chooser"
                :options="categorias"
                v-model="financing.categoria"
                :components="{ Deselect }"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></v-select>
            </base-input>
          </div>

          <div class="col-md-3" v-if="submodalidade == 'bacen'">
            <base-input required label="Bancarizador*">
              <v-select
                label="name"
                class="style-chooser"
                :options="bancarizadores"
                v-model="financing.bancarizador"
                :components="{ Deselect }"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></v-select>
            </base-input>
          </div>

          <div class="col-md-3">
            <base-input
              label="Status"
              :value="statusDescription(financing.status)"
              disabled
            ></base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label>Imagem (tamanho sugerido: 512 x 342)</label>
            <VueFileAgent
              v-model="fileRecords"
              :multiple="false"
              :deletable="true"
              :meta="false"
              :accept="'image/*'"
              :maxSize="'5MB'"
              :helpText="'Arraste uma imagem aqui'"
              :errorText="{
                type: 'Por favor, selecione uma imagem',
                size: 'As imagens não podem ter mais de 5MB',
              }"
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
              "
              @beforedelete="onBeforeDelete($event)"
            ></VueFileAgent>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-3" v-for="field in dynamicFields" :key="field.id">
            <label v-if="field.type == 'currency'" :for="field.id">{{
              field.label
            }}</label>
            <currency-input
              v-if="field.type == 'currency'"
              :id="field.id"
              class="form-control"
              v-model="financing.fields[field.reference].value"
              :placeholder="field.placeholder"
              :required="field.required"
              v-bind:readonly="field.readonly"
              currency="BRL"
              locale="pt-br"
              :auto-decimal-mode="true"
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
              "
            />
            <base-input
              v-if="field.type != 'currency' && field.type != 'percent' && field.type == 'number'"
              :type="field.type"
              :min="field.min"
              v-bind:readonly="field.readonly"
              :label="field.label"
              v-model="financing.fields[field.reference].value"
              :placeholder="field.placeholder"
              :required="field.required"
              @change="onInputNumberChange(field.reference, $event)"
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
              "
            ></base-input>
            <base-input
              v-if="field.type != 'currency' && field.type != 'percent' && field.type != 'number'"
              :type="field.type"
              :min="field.min"
              v-bind:readonly="field.readonly"
              :label="field.label"
              v-model="financing.fields[field.reference].value"
              :placeholder="field.placeholder"
              :required="field.required"
              @change="onInputDateChange(field.reference, $event)"
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
              "
            ></base-input>
            <base-input
              v-if="field.type != 'currency' && field.type == 'percent'"
              :type="field.type"
              :min="field.min"
              v-bind:readonly="field.readonly"
              :label="field.label"
              v-model="financing.fields[field.reference].value"
              :placeholder="field.placeholder"
              :required="field.required"
              v-mask="['#%', '##%', '###%']"
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
              "
            ></base-input>
          </div>

          <div class="col-md-3">
            <base-input required label="Estado*">
              <v-select
                label="uf"
                class="style-chooser"
                :options="estados"
                v-model="financing.estado"
                :components="{ Deselect }"
                @input="setEstadoSelected"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <em style="opacity: 0.9; color: #555"
                      ><b>{{ search }}</b> não encontrada</em
                    >
                  </template>
                </template>
              </v-select>
            </base-input>
          </div>

          <div class="col-md-3">
            <base-input required label="Cidade*">
              <v-select
                label="nome"
                class="style-chooser"
                :options="cidades"
                v-model="financing.cidade"
                :components="{ Deselect }"
                :loading="isLoadingCidades"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              >
                <template #spinner="{ loading }">
                  <div
                    v-if="loading"
                    style="border-left-color: rgba(88, 151, 251, 0.71)"
                    class="vs__spinner"
                  ></div>
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <em style="opacity: 0.9; color: #555"
                      ><b>{{ search }}</b> não encontrada</em
                    >
                  </template>
                  <em style="opacity: 0.9; color: #555" v-else
                    >Selecione um <b>estado</b></em
                  >
                </template>
              </v-select>
            </base-input>
          </div>
        </div>

        <div class="row" v-if="modalidade == 'equity' && showDebtFields">

          <div class="col-md-3">
            <base-input
              label="Rentabilidade*"
              placeholder="10,00% a.a."
              v-model="financing.rentabilidade"
              required
              :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
            ></base-input>
          </div>

          <div class="col-md-3">
            <base-input
              label="Prazo*"
              placeholder="6 meses"
              v-model="financing.prazo"
              required
              :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
            ></base-input>
          </div>

        </div>

        <div class="row" v-if="modalidade == 'doacao'">
          <div class="col-md-3">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="financing.hasMinimalCapture"
                />
                Possui captação mínima
                <span class="form-check-sign">
                  <span class="check"></span>
                </span>
              </label>
            </div>
          </div>

          <div class="col-md-3" v-if="financing.hasMinimalCapture">
            <label>Valor da captação mínima</label>
            <currency-input
              class="form-control"
              v-model="financing.minimalCapture"
              placeholder="Ex: 12.000,00"
              currency="BRL"
              locale="pt-br"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-3">
            <label for="quillEditor">Detalhes</label>
            <quill-editor
              ref="quillEditor"
              v-model="financing.content"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              @change="onEditorChange($event)"
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
              "
            />
          </div>
        </div>

        <div class="row" v-if="modalidade == 'doacao'">
          <div class="col-md-12 mt-3">
            <base-checkbox
              v-model="isForPJ"
              v-on:input="checkIsForPJ"
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada)
              "
            >
              Confirmo que possuo autorização da seguinte entidade beneficiária
              para atuar nesta plataforma.
            </base-checkbox>
          </div>
        </div>

        <template v-if="isForPJ">
          <div class="row">
            <div class="col-md-4 mt-3">
              <base-input
                label="CNPJ"
                v-model="cnpjForSearch"
                placeholder="Informe o CNPJ da entidade"
                v-mask="['##.###.###/####-##']"
                v-on:input="changeCNPJ"
                required
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
              <div
                class="error"
                v-if="
                  hasCnpj && cnpjForSearch != '' && $v.cnpjForSearch.$invalid
                "
              >
                Informe um CNPJ válido.
              </div>
              <label v-if="isLoadingPessoaPJ" style="cursor: default"
                ><i>Pesquisando...</i></label
              >
            </div>
            <div class="col-md-4 mt-3">
              <base-input
                label="Razão Social"
                v-model="pessoaPJ.nome"
                placeholder="Informe a razão social da entidade"
                required
                v-if="showPJFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
            </div>
            <div class="col-md-4 mt-3">
              <base-input
                label="Nome Fantasia"
                v-model="pessoaPJ.dados_complementares.nome_fantasia"
                placeholder="Informe o nome fantasia da entidade"
                required
                v-if="showPJFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <base-input
                label="CEP"
                v-model="cepForSearch"
                placeholder="Informe o CEP do endereço"
                v-mask="['##.###-###']"
                v-on:input="changeCEP"
                required
                v-if="showPJFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
              <label v-if="isLoadingEnderecoPessoaPJ" style="cursor: default"
                ><i>Pesquisando...</i></label
              >
            </div>
            <div class="col-md-3">
              <base-input
                label="Rua"
                v-model="pessoaPJ.endereco.rua"
                placeholder="Informe a rua do endereço"
                required
                v-if="showPJFields && showPJAddressFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
            </div>
            <div class="col-md-3">
              <base-input
                label="Número"
                v-model="pessoaPJ.endereco.numero"
                placeholder="Informe o número do endereço"
                required
                v-if="showPJFields && showPJAddressFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
            </div>
            <div class="col-md-3">
              <base-input
                label="Complemento"
                v-model="pessoaPJ.endereco.complemento"
                placeholder="Informe o complemento do endereço"
                v-if="showPJFields && showPJAddressFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <base-input
                label="Bairro"
                v-model="pessoaPJ.endereco.bairro"
                placeholder="Informe o bairro do endereço"
                required
                v-if="showPJFields && showPJAddressFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
            </div>
            <div class="col-md-3">
              <base-input
                required
                label="Estado*"
                v-if="showPJFields && showPJAddressFields"
              >
                <v-select
                  label="uf"
                  class="style-chooser"
                  :options="estados"
                  v-model="pessoaPJ.endereco.estado"
                  :components="{ Deselect }"
                  @input="setPJEstadoSelected"
                  :disabled="
                    isCurador ||
                    (financing.status != CWLStatusCampanha.EmCriacao &&
                      financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                    || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                  "
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      <em style="opacity: 0.9; color: #555"
                        ><b>{{ search }}</b> não encontrada</em
                      >
                    </template>
                  </template>
                </v-select>
              </base-input>
            </div>
            <div class="col-md-3">
              <base-input
                required
                label="Cidade*"
                v-if="showPJFields && showPJAddressFields"
              >
                <v-select
                  label="nome"
                  class="style-chooser"
                  :options="cidades"
                  v-model="pessoaPJ.endereco.cidade"
                  :components="{ Deselect }"
                  :loading="isLoadingPJCidades"
                  :disabled="
                    isCurador ||
                    (financing.status != CWLStatusCampanha.EmCriacao &&
                      financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                    || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                  "
                >
                  <template #spinner="{ loading }">
                    <div
                      v-if="loading"
                      style="border-left-color: rgba(88, 151, 251, 0.71)"
                      class="vs__spinner"
                    ></div>
                  </template>
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      <em style="opacity: 0.9; color: #555"
                        ><b>{{ search }}</b> não encontrada</em
                      >
                    </template>
                    <em style="opacity: 0.9; color: #555" v-else
                      >Selecione um <b>estado</b></em
                    >
                  </template>
                </v-select>
              </base-input>
            </div>
            <div class="col-md-3 mt-3">
              <button class="btn btn-primary" @click="complementarEmpresa">
                Complementar Empresa
              </button>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-3">
              <base-input
                label="Responsável pela empresa*"
                v-model="pessoaResponsavelPelaEmpresa.nome"
                placeholder="Informe o nome do responsável"
                required
                v-if="showPJFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
            </div>
            <div class="col-md-3">
              <base-input
                label="CPF do responsável*"
                v-model="pessoaResponsavelPelaEmpresa.documento.numero"
                placeholder="Informe o CPF"
                v-mask="['###.###.###-##']"
                required
                v-if="showPJFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
            </div>
            <div class="col-md-3">
              <base-input
                label="E-mail do responsável*"
                v-model="pessoaResponsavelPelaEmpresa.email.email"
                placeholder="Informe o e-mail"
                required
                v-if="showPJFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
            </div>
            <div class="col-md-3">
              <base-input
                label="Telefone do responsável*"
                v-model="pessoaResponsavelPelaEmpresa.telefone.telefone"
                placeholder="Informe o telefone"
                v-mask="['(##) #####-####']"
                required
                v-if="showPJFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
            </div>
            <div class="col-md-3">
              <base-input
                label="Data de nascimento do responsável*"
                v-model="pessoaResponsavelPelaEmpresa.dados_complementares.birthday"
                placeholder="Informe a data de nascimento"
                v-mask="['##/##/####']"
                required
                v-if="showPJFields"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
              ></base-input>
            </div>
          </div> -->
        </template>

        <div class="row" v-if="modalidade == 'doacao'">
          <div class="col-md-12 mt-3">
            <base-checkbox
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada)
              "
              v-model="publicarAutomaticamente"
              helperContent="Se marcar esta opção, sua campanha será publicada de forma automática depois que a plataforma aprová-la.<br /><br />Se deixar desmarcado, você terá que publicar manualmente após a aprovação."
            >
              Quero que a campanha seja publicada automaticamente após a
              aprovação
            </base-checkbox>
          </div>
        </div>

        <h5 slot="header" class="title mt-3">Avalistas</h5>
        <div class="row">
          <div class="col-md-6">
            <base-input required label="Avalista Principal">
              <v-select
                label="name"
                class="style-chooser"
                :options="avalistas"
                v-model="financing.avalistaPrincipal"
                :components="{ Deselect }"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "
                required
                @input="handleChangeAvalistaPrincipal"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <em style="opacity: 0.9; color: #555"
                      ><b>{{ search }}</b> não encontrado</em
                    >
                  </template>
                </template>
              </v-select>
            </base-input>
          </div>
          <div class="col-md-6">
            <base-input label="Demais Avalistas">
              <v-select
                label="name"
                class="style-chooser"
                :options="avalistas.filter(avalista => avalista !== financing.avalistaPrincipal)"
                v-model="financing.demaisAvalistas"
                :components="{ Deselect }"
                :disabled="
                  isCurador ||
                  (financing.status != CWLStatusCampanha.EmCriacao &&
                    financing.status != CWLStatusCampanha.Reprovada && !isGestor)
                  || (financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )
                "

                multiple
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    <em style="opacity: 0.9; color: #555"
                      ><b>{{ search }}</b> não encontrado</em
                    >
                  </template>
                </template>
              </v-select>
            </base-input>
          </div>

        </div>

        <div class="row">
          <div class="col-md-12 mt-3">
            <base-checkbox
              v-model="acceptTerm"
              :disabled="
                isCurador ||
                (financing.status != CWLStatusCampanha.EmCriacao &&
                  financing.status != CWLStatusCampanha.Reprovada)
              "
            >
              Li e concordo com as
              <span style="color: blue" @click="openUseTerms">
                Condições, Termos de Uso</span
              >
              e
              <span style="color: blue" @click="openTaxasPrazos">
                Taxas e Prazos</span
              >
            </base-checkbox>
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-sm mt-3"
          :disabled="isCurador || !acceptTerm || blockByPJError || updating"
          v-if="
            (isAdminOrGestor && financing.status == this.CWLStatusCampanha.RemovidaDoAr) ||
            (!isCurador &&
            (financing.status == this.CWLStatusCampanha.EmCriacao || financing.status == this.CWLStatusCampanha.Reprovada))
          "
        >
          Salvar
        </button>

        <button
          @click="salvarEnviarParaAnalise"
          class="btn btn-sm mt-3"
          :disabled="isCurador || !acceptTerm || blockByPJError || updating || (!financingAccounts.beneficiary.id || !financingAccounts.platform.id)"
          v-if="
            (isAdminOrGestor || !isCurador) &&
            (financing.status == this.CWLStatusCampanha.EmCriacao ||
              financing.status == this.CWLStatusCampanha.Reprovada)
          "
        >
          Salvar e enviar para análise<i
            class="tim-icons icon-send"
            style="margin-left: 8px"
          ></i>
        </button>

        <button
          @click="publicarCampanha"
          class="btn btn-sm mt-3"
          :disabled="
            isCurador ||
            (financing.status != this.CWLStatusCampanha.Aprovada &&
              financing.status != this.CWLStatusCampanha.RemovidaDoAr) ||
            financing.status == this.CWLStatusCampanha.Suspensa || financing.status == this.CWLStatusCampanha.Cancelada ||
            !financingAccounts.beneficiary.id ||
            !financingAccounts.platform.id ||
            (!financingAccounts.beneficiary.id || !financingAccounts.platform.id)
          "
          v-if="
            (isGestor ||
              (modalidade == 'doacao' && !isAdminOrGestorOrCurador)) &&
            (financing.status == this.CWLStatusCampanha.Aprovada ||
              financing.status == this.CWLStatusCampanha.RemovidaDoAr)
          "
        >
          {{ modalidade == 'doacao' ? 'Publicar campanha' : 'Publicar oferta' }}
          <i class="tim-icons icon-spaceship" style="margin-left: 8px"></i>
        </button>
      </form>

      <modal :show="showModalTermos" @close="showModalTermos = false">
        <div class="term-of-use">
          Estas são nossas <b>Condições</b> e <b>Termos de Uso</b> da
          plataforma. Leia com atenção e qualquer dúvida, entre em contato.
          <button
            style="
              background-color: rgb(255, 255, 255);
              border: 1px solid #aaa;
              border-radius: 20px;
              height: 40px;
              width: 40px;
              position: absolute;
              right: 0;
              top: 10px;
              cursor: pointer;
              color: #888;
            "
            @click="showModalTermos = false"
          >
            <i class="tim-icons icon-simple-remove"></i>
          </button>
          <br />
          <br />
          <div
            class="card"
            style="
              background-color: #fff !important;
              max-width: 1024px !important;"
          >
            <div class="login-form" style="max-width: 1024px">
              <div id="parsedContentTermosUso"></div>
            </div>
          </div>
        </div>
      </modal>

      <modal :show="showModalTaxasPrazos" @close="showModalTaxasPrazos = false">
        <div class="term-of-use">
          Estas são nossas <b>Taxas</b> e <b>Prazos</b> da plataforma. Leia com
          atenção e qualquer dúvida, entre em contato.
          <button
            style="
              background-color: rgb(255, 255, 255);
              border: 1px solid #aaa;
              border-radius: 20px;
              height: 40px;
              width: 40px;
              position: absolute;
              right: 0;
              top: 10px;
              cursor: pointer;
              color: #888;"
            @click="showModalTaxasPrazos = false"
          >
            <i class="tim-icons icon-simple-remove"></i>
          </button>
          <br />
          <br />
          <div
            class="card"
            style="background-color: #fff !important; max-width: 1024px !important; "
          >
            <div class="login-form" style="max-width: 1024px">
              <div id="parsedContentTaxasPrazo"></div>
            </div>
          </div>
        </div>
      </modal>
    </card>

    <card
      v-if="
        isCurador ||
        isAdminOrGestor ||
        financing.status == this.CWLStatusCampanha.Reprovada
      "
    >
      <h5 slot="header" class="title">Curadoria</h5>

      <div class="row" v-if="isCurador || isAdminOrGestor">
        <div class="col-md-6 pr-md-1">
          <base-input
            label="Criador da campanha"
            placeholder=""
            v-model="criador_da_campanha_nome"
            disabled
            v-if="modalidade == 'doacao'"
          ></base-input>
          <base-input
            label="Criador da oferta"
            placeholder=""
            v-model="criador_da_campanha_nome"
            disabled
            v-if="modalidade == 'equity'"
          ></base-input>
        </div>
        <div class="col-md-6 pr-md-1">
          <base-input
            label="E-mail do criador"
            placeholder=""
            v-model="criador_da_campanha_email"
            disabled
          ></base-input>
        </div>
      </div>

      <div class="row" v-if="isCurador || isAdminOrGestor">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Telefone do criador"
            placeholder=""
            v-model="criador_da_campanha_telefone"
            disabled
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="CPF do criador"
            placeholder=""
            v-model="criador_da_campanha_cpf"
            disabled
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Data de nascimento do criador"
            placeholder=""
            v-model="criador_da_campanha_nascimento"
            disabled
          ></base-input>
        </div>
      </div>

      <div class="row" v-if="isCurador || isAdminOrGestor">
        <div class="col-md-12">
          <label for="quillEditor">Observações</label>
          <quill-editor
            ref="quillEditor"
            v-model="financing.curadoria.observacao"
            :options="editorCuradoriaOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
            @change="onCuradoriaEditorChange($event)"
            :disabled="
              !isCurador && financing.status != CWLStatusCampanha.Reprovada
            "
          />
        </div>
      </div>

      <div class="row" v-if="!isCurador && !isAdminOrGestor">
        <div class="col-md-12">
          <label
            v-if="financing.users.reproved_by"
            class="control-label"
            style="cursor: default"
          >
            Sua {{ modalidade == 'doacao' ? 'campanha' : 'oferta' }} foi
            analisada pela curadoria e não foi aprovada pelos motivos abaixo:
          </label>
          <label
            v-if="!financing.users.reproved_by"
            class="control-label"
            style="cursor: default"
            >A sua {{ modalidade == 'doacao' ? 'campanha' : 'oferta' }} foi
            reprovada pelos motivos abaixo:</label
          >

          <div class="form-control" id="parsedContent"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <button
            type="button"
            class="btn btn-sm mt-3"
            @click="aprovarCampanha"
            v-if="isCurador || isAdminOrGestor"
            :disabled="financing.status != CWLStatusCampanha.AguardandoAnalise"
          >
            Aprovar
          </button>
          <button
            type="button"
            class="btn btn-sm mt-3 btn-warning"
            @click="reprovarCampanha"
            v-if="isCurador || isAdminOrGestor"
            :disabled="financing.status != CWLStatusCampanha.AguardandoAnalise"
          >
            Reprovar
          </button>
        </div>
      </div>
    </card>


    <card v-if="isGestor">
      <h5 slot="header" class="title">Gestor</h5>

      <div class="row">
        <div class="col-md-10">
          <base-input
            label="Plano de Aplicação/Prestação de Contas"
            v-model="financing.balancete"
            placeholder="Link para Plano de Aplicação/Prestação de Contas"
            :readonly="!isGestor"
          ></base-input>
        </div>

        <div class="col-md-2 pr-md-1">
          <div class="form-group">
            <label class="control-label"></label>
            <button
              type="button"
              class="btn btn-sm form-control"
              style="color: #fff;"
              @click="salvarBalancete()"
              :disabled="financing.status == this.CWLStatusCampanha.Suspensa || financing.status == this.CWLStatusCampanha.Cancelada"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>

      <div class="row" v-if="modalidade == 'equity' && isProductSubtypeP1">
        <div class="ml-3" style="color: #333; font-weight: 300; font-size: 0.8rem; margin-bottom: 10px; margin-top: 10px;">
          Informações Relevantes
        </div>

        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <base-input
                type="text"
                v-model="infoTitle"
                placeholder="Digite o título"
              />
            </div>

            <div class="col-md-6">
              <div class="row">
                <div style="display: flex; align-items: center;">
                <div class="upload-button">
                  <svg class="mr-1" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.3542 7.61625C21.0468 4.14656 18.2362 1.94904 13.9908 1.94904C8.58888 1.94904 4.80935 5.96013 4.48453 11.1933C1.88013 12.0262 0 14.6831 0 17.6925C0 21.3764 2.89594 24.4884 6.32294 24.4884H9.03138C9.54888 24.4884 9.96888 24.0684 9.96888 23.5509C9.96888 23.0329 9.54888 22.6134 9.03138 22.6134H6.32294C3.91169 22.6134 1.84544 20.3601 1.84544 17.6925C1.84544 15.3351 3.49825 13.2084 5.57153 12.8522L6.42231 12.7054L6.34685 11.8462L6.33981 11.8354C6.33981 7.26888 9.34497 3.8245 13.9908 3.8245C17.7145 3.8245 19.8066 5.65919 20.7741 8.78857L20.9747 9.43404L21.6501 9.44857C25.1329 9.52169 28.1864 12.3473 28.1864 15.8578C28.1864 19.0528 26.069 22.6134 22.8633 22.6134H20.7211C20.2036 22.6134 19.7836 23.0334 19.7836 23.5509C19.7836 24.0689 20.2036 24.4884 20.7211 24.4884L22.8562 24.4857C27.5437 24.3619 29.9854 19.884 29.9854 15.8579C29.9854 11.5304 26.594 8.0724 22.3542 7.61631L22.3542 7.61625ZM15.7026 15.0862C15.6975 15.0805 15.6956 15.0759 15.6923 15.0712L15.4551 14.8232C15.3253 14.6859 15.1523 14.6179 14.9793 14.6188C14.8063 14.6174 14.6343 14.6859 14.5021 14.8232L14.2649 15.0711C14.2603 15.0758 14.2593 15.0815 14.2551 15.0862L10.8712 18.8287C10.6092 19.1024 10.6092 19.5454 10.8712 19.8201L11.1074 19.9804C11.3695 20.2541 11.7942 20.1655 12.0557 19.8918L14.0512 17.6671V27.1134C14.0512 27.6309 14.4712 28.0509 14.9887 28.0509C15.5062 28.0509 15.9262 27.6309 15.9262 27.1134V17.676L17.9878 19.9503C18.2498 20.2241 18.6736 20.3127 18.9351 20.0389L19.1714 19.8786C19.4334 19.6039 19.4334 19.161 19.1714 18.8872L15.7026 15.0862Z" fill="#D8D6DE"/>
                  </svg>

                  <label for="upload-file" class="ml-1">Selecionar arquivo pdf</label>
                  <input
                    @change="selectedFile()"
                    type="file"
                    name="file"
                    ref="uploadFile"
                    id="upload-file"
                    accept="image/*, video/*, .pdf, .doc, .xlsx"
                    required
                  />
                </div>

                  <h6 v-if="file.selectedFilename" class="ml-2 mt-1 mr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48">
                      <circle cx="24" cy="24" r="20" fill="#00c600"></circle><path fill="#fff" d="M22.491,30.69c-0.576,0-1.152-0.22-1.591-0.659l-6.083-6.084c-0.879-0.878-0.879-2.303,0-3.182 c0.878-0.879,2.304-0.879,3.182,0l6.083,6.084c0.879,0.878,0.879,2.303,0,3.182C23.643,30.47,23.067,30.69,22.491,30.69z"></path><path fill="#fff" d="M22.491,30.69c-0.576,0-1.152-0.22-1.591-0.659c-0.879-0.878-0.879-2.303,0-3.182l9.539-9.539 c0.878-0.879,2.304-0.879,3.182,0c0.879,0.878,0.879,2.303,0,3.182l-9.539,9.539C23.643,30.47,23.067,30.69,22.491,30.69z"></path>
                  </svg>
                  <!-- {{ file.selectedFilename }} -->
                  <!-- <i
                    class="fas fa-times-circle ml-3"
                    style="color: #fa6c6e; font-size: 15px;"
                    @click="file.selectedFilename = ''"
                    ></i> -->
                  </h6>
                <button
                  v-if="file.selectedFilename"
                  type="button"
                  class="btn btn-sm btn-warning"
                  @click="file.selectedFilename = ''"
                  >
                  Remover arquivo
                </button>
              </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6">
                  <textarea
                    style="width: 100%;"
                    type="text"
                    placeholder="Digite a descrição"
                    v-model="infoDescription"
                    rows="4"
                    cols="40"
                    class="info-gestor-textarea"
                  ></textarea>
                  </div>
                  </div>
             </div>
          </div>

        </div>

        <div class="col-md-2 pr-md-1 mt-2">
          <div class="form-group">
            <label class="control-label"></label>
            <button
              type="button"
              class="btn btn-sm"
              style="color: #fff;"
              @click="salvarInformacoesRelevantes()"
              :disabled="financing.status == this.CWLStatusCampanha.Suspensa || financing.status == this.CWLStatusCampanha.Cancelada"
            >
              Salvar
            </button>
          </div>
        </div>

        <card v-for="(item) in relevantInfos" class="col-md-12" style="color: #494949; letter-spacing: 1px; margin-bottom: 20px;" v-if="isProductSubtypeP1">
          <div class="row" :key="item.id">

            <div class="col-md-3">
              <strong style="font-weight: 600;">TÍTULO </strong>
              <p>{{ item.infoTitle }}</p>
            </div>
            <div class="col-md-2">
              <strong style="font-weight: 600;">DATA </strong>
              <p>{{ item.created_at }}</p>
            </div>
            <div class="col-md-6">
              <strong style="font-weight: 600;">DESCRIÇÃO </strong>
              <p>{{ item.infoDescription }}</p>
            </div>

            <div class="col-md-1" style="display: flex; gap: 10px; align-items: center; padding: 0; justify-content: flex-end;">
              <!-- dell icon -->
              <div v-if="item.infoFile">
                <!-- <p>{{ item.infoFile }}</p> -->
                <svg
                  @click="downloadPDF(item.infoFile)"
                  width="30" height="65" viewBox="0 0 30 65" fill="none" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;"
                >
                <path d="M15 31.9844V42.6094M15 42.6094L18.75 38.8594M15 42.6094L11.25 38.8594M10 26.4044C10.9354 26.5382 11.8019 26.9725 12.4688 27.6419M21.875 35.1094C23.7738 35.1094 25 33.5706 25 31.6719C25 30.9202 24.7535 30.1892 24.2983 29.5909C23.8432 28.9926 23.2045 28.56 22.48 28.3594C22.3686 26.9575 21.7875 25.6341 20.8309 24.6033C19.8742 23.5724 18.5979 22.8943 17.2081 22.6787C15.8184 22.463 14.3966 22.7223 13.1725 23.4148C11.9484 24.1072 10.9936 25.1922 10.4625 26.4944C9.3443 26.1844 8.14875 26.3314 7.13886 26.9029C6.12898 27.4744 5.38749 28.4237 5.07752 29.5419C4.76755 30.6601 4.91448 31.8557 5.486 32.8656C6.05752 33.8754 7.0068 34.6169 8.12502 34.9269" stroke="#D8D6DE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>

              <a @click="deletarInformacoesRelevantes(item)" href="#" style="color: red" title="Excluir usuário" class="button-action">
                <i class="tim-icons icon-trash-simple"></i>
              </a>
            </div>
          </div>
        </card>
      </div>

      <div class="row">
        <div class="col-md-12 pr-md-1">
          <button
            type="button"
            class="btn btn-sm mt-3 btn-warning"
            :disabled="financing.status == this.CWLStatusCampanha.RemovidaDoAr"
            @click="updateCampaignStatus(CWLStatusCampanha.RemovidaDoAr)"
          >
            Retirar oferta do ar
          </button>

          <button
            v-if="modalidade == 'equity'"
            type="button"
            class="btn btn-sm mt-3 btn-warning"
            :disabled="financing.status != this.CWLStatusCampanha.RemovidaDoAr || financing.status == this.CWLStatusCampanha.Suspensa"
            @click="updateCampaignStatus(CWLStatusCampanha.Suspensa)"
          >
            Suspender Oferta
          </button>

          <button
            v-if="modalidade == 'equity'"
            type="button"
            class="btn btn-sm mt-3 btn-warning"
            :disabled="financing.status != this.CWLStatusCampanha.RemovidaDoAr || financing.status == this.CWLStatusCampanha.Cancelada"
            @click="updateCampaignStatus(CWLStatusCampanha.Cancelada)"
          >
            Cancelar Oferta
          </button>

          <button
            v-if="modalidade == 'equity'"
            type="button"
            class="btn btn-sm mt-3 btn-default"
            :disabled="financing.status != this.CWLStatusCampanha.RemovidaDoAr || financing.status == this.CWLStatusCampanha.Cancelada"
            @click="updateCampaignStatus(CWLStatusCampanha.ConcluidaComSucesso)"
          >
            Concluir Oferta
          </button>
          <button
            type="button"
            class="btn btn-sm mt-3 btn-warning"
            :disabled="financing.status != this.CWLStatusCampanha.RemovidaDoAr"
            @click="preConfirmarExclusaoOferta()"
          >
            Excluir Oferta
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 mt-3">
          <label for="">Repasse de Recebíveis</label><br />
          <button v-if="!financing.repasse_habilitado" class="btn btn-primary" @click="habilitarRepasse" :disabled="isLoadingButtonDisabled || financing.status != this.CWLStatusCampanha.ConcluidaComSucesso">
            Habilitar Repasse
          </button>
          <button v-if="financing.repasse_habilitado" class="btn btn-primary" @click="desabilitarRepasse" :disabled="isLoadingButtonDisabled || financing.status != this.CWLStatusCampanha.ConcluidaComSucesso">
            Desabilitar Repasse
          </button>
        </div>
      </div>

      <div class="row" v-if="modalidade == 'equity'">
        <div class="col-md-4 mt-3">
          <label for="">Relatório de Oferta</label><br />
          <button class="btn btn-primary" @click="generatePdf">
            Gerar Relatório de Oferta
          </button>
        </div>
      </div>
      <button
          v-if="isProductSubtypeP1"
          type="button"
          class="btn btn-sm mt-3"
          @click="verForum"
        >
          Fórum
        </button>

      <div class="row" v-if="submodalidade == 'bacen' && (financing.status == CWLStatusCampanha.ConcluidaComSucesso || financing.status == CWLStatusCampanha.ConcluidaSemSucesso)">
        <div class="col-md-5 mt-5">
          <label for="">Operação de Investimento</label><br />
          <button class="btn btn-primary" @click="validarQualFechamentoOperacaoChamar">
            Confirmar Operação de Investimento
          </button>
        </div>
      </div>
    </card>

    <card v-if="isAdmin">
      <h5 slot="header" class="title">Administrador</h5>

      <div class="row">
        <div class="col-md-4">
          <base-input
            label="Subconta ID"
            v-model="financingAccounts.beneficiary.id"
            placeholder="Subconta ID"
            disabled
          ></base-input>
        </div>
        <div class="col-md-4">
          <base-input
            label="Subconta Token Sandbox"
            v-model="financingAccounts.beneficiary.token_sandbox"
            placeholder="Subconta Token Sandbox"
            disabled
          ></base-input>
        </div>
        <div class="col-md-4">
          <base-input
            label="Subconta Token Produtivo"
            v-model="financingAccounts.beneficiary.token"
            placeholder="Subconta Token Produivo"
            disabled
          ></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <base-input
            label="Subconta CWL ID"
            v-model="financingAccounts.platform.id"
            placeholder="Subconta CWL ID"
            disabled
          ></base-input>
        </div>
        <div class="col-md-4">
          <base-input
            label="Subconta CWL Token Sandbox"
            v-model="financingAccounts.platform.token_sandbox"
            placeholder="Subconta CWL Token Sandbox"
            disabled
          ></base-input>
        </div>
        <div class="col-md-4">
          <base-input
            label="Subconta CWL Token Produtivo"
            v-model="financingAccounts.platform.token"
            placeholder="Subconta CWL Token Produivo"
            disabled
          ></base-input>
        </div>
      </div>

      <div class="row" v-if="financingAccounts.agent">
        <div class="col-md-4">
          <base-input
            label="Subconta Agente (API3) ID"
            v-model="financingAccounts.agent.id"
            placeholder="Subconta CWL ID"
            disabled
          ></base-input>
        </div>
        <div class="col-md-4">
          <base-input
            label="Subconta Agente (API3) Token Sandbox"
            v-model="financingAccounts.agent.token_sandbox"
            placeholder="Subconta CWL Token Sandbox"
            disabled
          ></base-input>
        </div>
        <div class="col-md-4">
          <base-input
            label="Subconta Agente (API3) Token Produtivo"
            v-model="financingAccounts.agent.token"
            placeholder="Subconta CWL Token Produivo"
            disabled
          ></base-input>
        </div>
      </div>
    </card>

    <card>
      <h5 slot="header" class="title">
        Dados Bancários
        <small id="dadosBancariosHelp" class="form-text text-muted">{{
          textoDadosBancarios
        }}</small>
      </h5>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input required label="Banco">
            <v-select
              label="name"
              class="style-chooser"
              :options="bancos"
              v-model="dados_bancarios.banco"
              :components="{ Deselect }"
              :disabled="(financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )"
              required
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  <em style="opacity: 0.9; color: #555"
                    ><b>{{ search }}</b> não encontrado</em
                  >
                </template>
              </template>
            </v-select>
          </base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Agência"
            placeholder="Informe sua agência"
            v-mask="['####']"
            v-model="dados_bancarios.agencia_numero"
            :disabled="(financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Dígito da agência"
            placeholder="Dígito"
            v-mask="['#']"
            v-model="dados_bancarios.agencia_digito"
            :disabled="(financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )"
            required
          ></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Conta"
            placeholder="Informe o número da sua conta"
            v-mask="['####################']"
            v-model="dados_bancarios.conta_numero"
            :disabled="(financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Dígito da conta"
            placeholder="Dígito"
            v-mask="['#']"
            v-model="dados_bancarios.conta_digito"
            :disabled="(financing.status != CWLStatusCampanha.RemovidaDoAr && isGestor )"
            required
          ></base-input>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-sm mt-3"
        @click="salvarDadosBancarios"
        :disabled="isCurador || !acceptTerm || blockByPJError || financing.status == this.CWLStatusCampanha.Suspensa || financing.status == this.CWLStatusCampanha.Cancelada"
      >
        Salvar dados bancários
      </button>
    </card>

    <card v-if="modalidade == 'equity'">
      <h5 slot="header" class="title">
        {{ $t('financing.customContractTitle') }}
      </h5>
      <h5 slot="header" class="subtitle">
        {{ $t('financing.customContractSubtitle') }}
      </h5>

      <base-checkbox :checked="financing.possui_contrato_personalizado" v-on:input="contratoPersonalizadoChecked">
        Essa oferta possui um contrato personalizado
      </base-checkbox>

      <div class="row">
        <div class="col-md-12 mt-3">
          <quill-editor
            ref="quillEditorContratoPersonalizado"
            v-model="financing.contrato_personalizado"
            :options="editorContratoPersonalizadoOptions"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
            @change="onContratoPersonalizadoChange($event)"
            :disabled="!financing.possui_contrato_personalizado"
          />
        </div>
      </div>

      <button
        type="button"
        class="btn btn-sm mt-3"
        @click="salvarContratoPersonalizado"
        :disabled="
        financing.status == this.CWLStatusCampanha.Suspensa || financing.status == this.CWLStatusCampanha.Cancelada ||
        (isGestor && financing.status != this.CWLStatusCampanha.RemovidaDoAr)
        "
      >
        Salvar contrato personalizado
      </button>
    </card>

    <card v-if="isAgenteSocial">
      <h5 slot="header" class="title">Transações</h5>
      <div class="row">
        <div class="col-md-2 pr-md-1">
          <button
            type="button"
            class="btn btn-sm mt-3"
            @click="verTransacoesAgenteSocial"
          >
            Agente (API3)
          </button>
        </div>
      </div>
    </card>

    <card v-if="this.role == 'master' || this.role == 'gestor'">
      <h5 slot="header" class="title">Transações</h5>

      <div>
        <button
          type="button"
          class="btn btn-sm mt-3 ml-3"
          @click="verTransacoesMarketplace"
        >
          Marketplace
        </button>
        <button
          type="button"
          class="btn btn-sm mt-3 ml-3"
          @click="verTransacoesCampanha"
          v-if="modalidade == 'doacao'"
        >
          Campanha
        </button>
        <button
          type="button"
          class="btn btn-sm mt-3 ml-3"
          @click="verTransacoesCampanha"
          v-if="modalidade == 'equity'"
        >
          Oferta
        </button>
        <button type="button" class="btn btn-sm mt-3 ml-3" @click="verTransacoesCWL">
          CWL
        </button>
      </div>
    </card>

    <vue-topprogress ref="topProgress"></vue-topprogress>
    <v-tour name="myTour" :steps="steps" :options="myOptions"></v-tour>
    <report-layout
      id="myelement"
      :financing="financing"
      v-if="modalidade == 'equity'"
      :settings="allSettings"
    ></report-layout>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showExcluirOfertaModal },
            { 'd-none': !showExcluirOfertaModal },
            { 'modal-mini': true },
          ]"
          v-show="showExcluirOfertaModal"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showExcluirOfertaModal"
        >
          <div class="modal-dialog">
            <div class="modal-content confirmation-modal">
              <i class="fa fa-exclamation-triangle fa-5x"></i>

              <h1 class="modal-content-title">Atenção</h1>

              <p class="modal-content-description">
                <span>Você tem certeza?</span>
              </p>

              <div class="modal-buttons">
                <button @click="closeModal()" class="cancel-action">
                  Cancelar
                </button>

                <button
                  style="
                    margin-top: 20px;
                    background-color: #288cf7;
                    color: #fff;
                    width: 250px !important;
                  "
                  @click="confirmarExclusaoOferta()"
                >
                  Sim, quero excluir
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>

    <template>
      <SlideYUpTransition :duration="500">
        <div
          class="modal fade"
          @click.self="closeModal"
          :class="[
            { 'show d-block': showConfirmarInvestimentoModal },
            { 'd-none': !showConfirmarInvestimentoModal },
          ]"
          v-show="showConfirmarInvestimentoModal"
          tabindex="-1"
          role="dialog"
          :aria-hidden="!showConfirmarInvestimentoModal"
        >
          <div class="modal-dialog" style="max-width: 1000px !important;">
            <div class="modal-content confirmation-modal" style="margin-top: 50px; max-height: 500px; overflow: scroll; display: -webkit-box;">
              <div v-if="showConfirmarInvestimentoModalHeader && (bancarizadorEncerramentoOperacao && bancarizadorEncerramentoOperacao.tipo == 'Integrado')">
                <h1 class="modal-content-title">IMPORTAR OPERAÇÃO DE CRÉDITO</h1>

                <p
                  class="modal-content-description"
                >
                  Esta operação não poderá ser cancelada. <br /><br />

                  Você tem certeza?
                </p>

                <div class="modal-buttons">
                  <button @click="closeModal()" class="cancel-action">
                    Cancelar
                  </button>

                  <button
                    style="
                      margin-top: 20px;
                      background-color: #288cf7;
                      color: #fff;
                      width: 250px !important;
                    "
                    @click="confirmarOperacaoInvestimento()"
                  >
                    Sim, quero continuar
                  </button>

                  <div class="error" style="color: red;" v-if="operacaoErrorShown">
                    {{ operacaoErrorMessage }}
                  </div>
                </div>

                <br /><br />
              </div>

              <div v-if="!showConfirmarInvestimentoModalHeader">
                <h1 style="color: red !important;" v-if="operacaoEmAndamento" class="modal-content-title">NÃO FECHE ESTA JANELA. IMPORTAÇÃO EM ANDAMENTO.</h1>
                <h1 style="color: blue !important;" v-if="operacaoConcluida" class="modal-content-title">IMPORTAÇÃO CONCLUÍDA. VERIFIQUE O STATUS DE CADA OPERAÇÃO.</h1>
              </div>

              <table class="table table-hover" :style="(bancarizadorEncerramentoOperacao && bancarizadorEncerramentoOperacao.tipo == 'Manual') ? 'margin-top: -200px' : ''">
                <thead class="thead-light">
                  <tr>
                    <slot name="columns">
                      <th>Data</th>
                      <th>Nome</th>
                      <th>Valor</th>
                      <th>Status Pgto</th>
                      <th>CCB</th>
                      <th v-if="!(bancarizadorEncerramentoOperacao && bancarizadorEncerramentoOperacao.tipo == 'Manual')">Código Operação</th>
                      <th v-if="!(bancarizadorEncerramentoOperacao && bancarizadorEncerramentoOperacao.tipo == 'Manual')" style="text-align: center">Status</th>
                      <th v-if="!(bancarizadorEncerramentoOperacao && bancarizadorEncerramentoOperacao.tipo == 'Manual')" style="text-align: center">Documento</th>
                      <th style="text-align: center">Contrato CCB</th>
                    </slot>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(order, index) in orders"
                    :key="index"
                    style="cursor: default !important; height: 60px"
                  >
                    <td>
                      {{ formatDate(order.order_date) }}
                    </td>
                    <td>
                      {{ order.pessoa.nome }}
                    </td>
                    <td>
                      {{ order.item.value | currency }}
                    </td>
                    <td>
                      {{ order.status }}
                    </td>
                    <td>
                      {{ ( order.ccb && order.ccb.numero_ccb ) ? order.ccb.numero_ccb : "" }}
                    </td>
                    <td v-if="!(bancarizadorEncerramentoOperacao && bancarizadorEncerramentoOperacao.tipo == 'Manual')">
                      <span v-if="importacaoStatus(order) == 'importado'">
                        {{ order.ccb.importacao.operacao.operacao.operacao }}
                      </span>
                      <span v-if="importacaoStatus(order) != 'importado'">
                        --
                      </span>
                    </td>
                    <td style="text-align: center;" v-if="importacaoStatus(order) == 'executando' && !(bancarizadorEncerramentoOperacao && bancarizadorEncerramentoOperacao.tipo == 'Manual')">
                      <double-bounce></double-bounce>
                    </td>
                    <td style="text-align: center;" v-if="importacaoStatus(order) == 'pendente' && !(bancarizadorEncerramentoOperacao && bancarizadorEncerramentoOperacao.tipo == 'Manual')">
                      <i title="Pendente de Envio" class="tim-icons icon-cloud-upload-94"></i>
                    </td>
                    <td style="text-align: center;" v-if="importacaoStatus(order) == 'erro' && !(bancarizadorEncerramentoOperacao && bancarizadorEncerramentoOperacao.tipo == 'Manual')">
                      <i :title="importacaoErrorMessage(order)" class="tim-icons icon-alert-circle-exc"></i>
                    </td>
                    <td style="text-align: center;" v-if="importacaoStatus(order) == 'importado' && !(bancarizadorEncerramentoOperacao && bancarizadorEncerramentoOperacao.tipo == 'Manual')">
                      <i :title="'Código da operação: ' + order.ccb.importacao.operacao.operacao.operacao" class="tim-icons icon-check-2"></i>
                    </td>

                    <td style="text-align: center;" v-if="importacaoDocumentosStatus(order) == 'executando' && !(bancarizadorEncerramentoOperacao && bancarizadorEncerramentoOperacao.tipo == 'Manual')">
                      <double-bounce></double-bounce>
                    </td>
                    <td style="text-align: center;" v-if="importacaoDocumentosStatus(order) == 'pendente' && !(bancarizadorEncerramentoOperacao && bancarizadorEncerramentoOperacao.tipo == 'Manual')">
                      <i title="Pendente de Envio" class="tim-icons icon-cloud-upload-94" style="cursor: pointer;" @click="importarDocumentos(order)"></i>
                    </td>
                    <td style="text-align: center;" v-if="importacaoDocumentosStatus(order) == 'pendente_operacao' && !(bancarizadorEncerramentoOperacao && bancarizadorEncerramentoOperacao.tipo == 'Manual')">
                      <i title="Aguardando Operação" class="tim-icons icon-cloud-upload-94"></i>
                    </td>
                    <td style="text-align: center;" v-if="importacaoDocumentosStatus(order) == 'erro' && !(bancarizadorEncerramentoOperacao && bancarizadorEncerramentoOperacao.tipo == 'Manual')">
                      <i :title="importacaoDocumentosErrorMessage(order)" class="tim-icons icon-alert-circle-exc" style="cursor: pointer;" @click="importarDocumentos(order)"></i>
                    </td>
                    <td style="text-align: center;" v-if="importacaoDocumentosStatus(order) == 'importado' && !(bancarizadorEncerramentoOperacao && bancarizadorEncerramentoOperacao.tipo == 'Manual')">
                      <i title="Importado!" class="tim-icons icon-check-2"></i>
                    </td>

                    <td style="text-align: center;">
                      <i title="Download Contrato CCB" class="tim-icons icon-cloud-download-93" style="cursor: pointer;" @click="downloadContratoCCB(order)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </SlideYUpTransition>
    </template>
  </div>
</template>
